export interface RoomSelectionState {
	siteId: number
	buildingId: number
	floorId: number
}

export const defaultRoomSelectionState: RoomSelectionState = {
	siteId: 0,
	buildingId: 0,
	floorId: 0,
}

export const initialRoomSelectionState: RoomSelectionState = {
	siteId: 2,
	buildingId: 14,
	floorId: 0,
}

export type RoomSelectionAction =
	| {
		type: "siteChanged"
		payload: {
			siteId: number
			buildingId: number
		}
	}
	| { type: "buildingChanged", payload: number }
	| { type: "floorChanged", payload: number }
	| { type: "reset" }

export const RoomSelectionReducer = (state: RoomSelectionState, action: RoomSelectionAction): RoomSelectionState => {
	switch (action.type) {
		case "siteChanged":
			return {
				...defaultRoomSelectionState,
				siteId: action.payload.siteId,
				buildingId: action.payload.buildingId !== defaultRoomSelectionState.buildingId
					? action.payload.buildingId
					: defaultRoomSelectionState.buildingId
			}
		case "buildingChanged":
			return {
				...state,
				buildingId: action.payload,
				floorId: defaultRoomSelectionState.floorId
			}
		case "floorChanged":
			return {
				...state,
				floorId: action.payload
			}
		case "reset":
			return defaultRoomSelectionState
	}
}
