import {
	FC
} from "react"
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { LinkContainer } from "react-router-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen } from "@fortawesome/pro-solid-svg-icons"
import moment from "moment-timezone"
import BottomRightCornerActions from "./BottomRightCornerActions"
import ReadOnlyItem from "../appmodels/ReadOnlyItem"
import ItemIcon from "./ItemIcon"
import { itemEditorPath } from "../routes/routingPaths"
import EmployeeRecord from "./EmployeeRecord"
import { europeVienna } from "../data/dataHelper"
import {
	storageStateId,
	removedStateId,
	itemWithDeviceNameCategoryIds
} from "../data/itemHelper"
import RoomRecord from "./RoomRecord"
import {
	navigateToEmployee,
	navigateToRoom
} from "../routes/routingFunctions"
import style from "./style/ItemDetails.module.css"

interface Props {
	item: ReadOnlyItem,
}

const ItemDetails: FC<Props> = ({ item }) => {
	const navigation = useNavigate()

	const itemRoom = item.employee?.Room ?? item.room

	return <div className={style.itemDetails}>
		<h1 className={style.ellipsisOverflow}>
			{item.name}
		</h1>
		<h3>
			{item.inventoryNumber}
		</h3>
		<div className={style.twoColumnLayout}>
			<div className={style.formLabel}>
				Kategorie:
			</div>
			<div className={style.formLabel}>
				Status:
			</div>
			<div>
				<ItemIcon categoryId={item.categoryId} />
				{item.category}
			</div>
			<div>
				{item.state}
			</div>
		</div>
		{item.stateId !== removedStateId
			&& <>
				{item.stateId !== storageStateId
					&& <div className={style.employee}>
						<div className={style.formLabel}>
							Mitarbeiter:
						</div>
						{item.employee
							? <EmployeeRecord employee={item.employee} onClick={employeeId => navigateToEmployee(navigation, employeeId)} />
							: <>Kein Mitarbeiter zugewiesen.</>}
					</div>}
				<div>
					<div className={style.formLabel}>
						Raum:
					</div>
					{itemRoom
						? <div className={style.twoColumnLayout}>
							<RoomRecord room={itemRoom} onClick={roomId => navigateToRoom(navigation, roomId)} />
						</div>

						: <span>Kein Raum zugewiesen.</span>}
				</div>
			</>}
		{itemWithDeviceNameCategoryIds.has(item.categoryId)
			&& <div>
				<div className={style.formLabel}>
					Gerätebezeichnung:
				</div>
				<div>
					{item.deviceName}
				</div>
			</div>}
		{item.categoryId === 3 &&
			<div>
				<div className={style.formLabel}>
					Lampenstunden:
				</div>
				<div>
					{item.lampHours}
				</div>
			</div>
		}<div>
			<div className={style.formLabel}>
				Kommentar:
			</div>
			<div>
				{item.comment}
			</div>
		</div>
		<div>
			<div className={style.formLabel}>
				Seriennummer:
			</div>
			<div>
				{item.serialNumber}
			</div>
		</div>
		{item.categoryId === 4 &&
			<div>
				<div className={style.formLabel}>
					IMEI:
				</div>
				<div>
					{item.imei}
				</div>
			</div>}
		<div>
			<div className={style.formLabel}>
				Verlauf:
			</div>
			<div className={style.history}>
				{item.itemHistory.map((m, i) => <div key={i}>
					<div>
						{moment.tz(m.DateTime, europeVienna).format("DD.MM.YYYY")} {m.User.Surname} {m.User.Name}
					</div>
					<div className={style.historyItemContent}>
						{m.Changes}
					</div>
				</div>)}
			</div>
		</div>
		{item.stateId !== removedStateId
			&& <BottomRightCornerActions>
				<LinkContainer to={`${itemEditorPath}/${item.id}`}>
					<Button variant="secondary">
						<FontAwesomeIcon icon={faPen} size="2x" fixedWidth={true} className={style.edit}
							title="Artikel bearbeiten" />
					</Button>
				</LinkContainer>
			</BottomRightCornerActions>
		}
	</div>
}

export default ItemDetails
