type TupleHead<T> = T extends [infer P, ...any[]] ? P : never
type TupleTail<T> = T extends [any, ...infer P] ? P : never
type toBlobParametersTail = TupleTail<Parameters<HTMLCanvasElement["toBlob"]>>

export const canvasToBlobAsync = async (canvas: HTMLCanvasElement,
	...rest: [...toBlobParametersTail]) =>
	new Promise<TupleHead<Parameters<BlobCallback>>>(resolve => canvas.toBlob(blob => resolve(blob), ...rest))

export const groupBy = <TKey, TArrayItem>(
	getKey: (item: TArrayItem) => TKey,
	array: readonly TArrayItem[]) =>
	array.reduce((map, item) =>
		map.set(
			getKey(item),
			[...(map.get(getKey(item)) ?? []), item]),
		new Map<TKey, readonly TArrayItem[]>())

// https://github.com/charlie-tango/hooks/tree/master/packages/useNativeLazyLoading
export const imageLazyLoadingSupported = "loading" in HTMLImageElement.prototype
