import {
	FC,
	useState,
	useContext
} from "react"
import { DataContext } from "../data/dataContext"
import EmployeeSurnameFilter from "./EmployeeSurnameFilter"
import Employees from "./Employees"
import { getSurnameFilteredEmployees } from "../data/dataHelper"

interface Props {
	onEmployeeSelect: (employeeId: number) => void,
}

const EmployeeSelection: FC<Props> = ({
	onEmployeeSelect
}) => {
	const { employees } = useContext(DataContext)

	const [employeeSurnameFirstLetterFilter, setEmployeeSurnameFirstLetterFilter] = useState("")

	return <>
		<EmployeeSurnameFilter selectedEmployeeSurnameFirstLetter={employeeSurnameFirstLetterFilter}
			onFilter={setEmployeeSurnameFirstLetterFilter} />
		<Employees employees={getSurnameFilteredEmployees(employeeSurnameFirstLetterFilter, employees)}
			onEmployeeClick={onEmployeeSelect} />
	</>
}

export default EmployeeSelection
