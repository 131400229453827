import {
	AccountInfo,
	PublicClientApplication
} from "@azure/msal-browser"

export const scopes = [
	`api://${process.env.REACT_APP_MICROSOFT_IDENTITY_WEB_API_CLIENT_ID}/access_as_user`
]

export const publicClientApplication = new PublicClientApplication({
	auth: {
		clientId: `${process.env.REACT_APP_MICROSOFT_IDENTITY_CLIENT_ID}`,
		authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY}`
	},
	cache: {
		cacheLocation: "localStorage"
	}
})

export const getTokenRedirect = async (account: AccountInfo) => {
	try {
		return (await publicClientApplication.acquireTokenSilent({ account, scopes })).accessToken
	} catch (error) {
		publicClientApplication.acquireTokenRedirect({ scopes })
		throw new Error(redirectingErrorMessage)
	}
}

export const redirectingErrorMessage = "redirectingErrorMessage"
