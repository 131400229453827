import {
	FC
} from "react"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareSquare } from "@fortawesome/pro-solid-svg-icons"
import style from "./style/Share.module.css"

interface Props {
	title: string
	onShare: () => void,
}

const Share: FC<Props> = ({
	title,
	onShare
}) =>
	!!navigator.share
		? <Button variant={"secondary"}>
			<FontAwesomeIcon icon={faShareSquare} size="2x" fixedWidth={true} className={style.share}
				title={title} onClick={onShare} />
		</Button>
		: null


export default Share
