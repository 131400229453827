import {
	FC,
	useState,
	useEffect
} from "react"
import { AccountInfo } from "@azure/msal-browser"
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { history } from "../applicationInsights/applicationInsights"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import {
	publicClientApplication,
	scopes
} from "../authentication/PublicClientApplication"
import Inventur from "./Inventur"

const Authentication: FC = () => {
	const [accountInfo, setAccountInfo] = useState(null as null | AccountInfo)

	useEffect(() => {
		// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v1-migration.md#3-update-your-code
		(async () => {
			const tokenReponse = await publicClientApplication.handleRedirectPromise()
			if (tokenReponse)
				setAccountInfo(tokenReponse.account)
			else {
				const accounts = publicClientApplication.getAllAccounts()

				if (process.env.NODE_ENV === "development"
					&& accounts.length > 1) {
					if (process.env.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY === "imcfhkremsdev.onmicrosoft.com"
						&& accounts.some(s => s.username.endsWith("@imcfhkremsdev.onmicrosoft.com"))) {
						setAccountInfo(accounts.find(f => f.username.endsWith("@imcfhkremsdev.onmicrosoft.com")) ?? null)
					} else if (process.env.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY === "imcfhkrems.onmicrosoft.com"
						&& !accounts.some(s => s.username.endsWith("@imcfhkremsdev.onmicrosoft.com"))) {
						setAccountInfo(accounts.find(f => !f.username.endsWith("@imcfhkremsdev.onmicrosoft.com")) ?? null)
					}
				}

				if (accounts.length === 1 && accounts[0])
					setAccountInfo(accounts[0])

				if (accounts.length === 0)
					publicClientApplication.loginRedirect({ scopes })
			}
		})()
	}, [])

	return <>
		{accountInfo &&
			<HistoryRouter history={history}>
				{/* <MsalProvider instance={publicClientApplication}>
					<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}> */}
				<AuthenticationContext.Provider value={{ accountInfo }}>
					<Inventur />
				</AuthenticationContext.Provider>
				{/* </MsalAuthenticationTemplate>
				</MsalProvider> */}
			</HistoryRouter>}
	</>
}

export default Authentication
