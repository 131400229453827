import {
	FC,
	ReactNode,
	useContext,
	useCallback
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowToTop } from "@fortawesome/pro-solid-svg-icons"
import Button from "react-bootstrap/Button"
import style from "./style/BottomRightCornerActions.module.css"
import { ScrolledDownContext } from "../data/scrolledDownContext"

interface Props {
	children?: ReactNode,
}

const BottomRightCornerActions: FC<Props> = ({ children }) => {
	const scrolled100vh = useContext(ScrolledDownContext)

	const scrollToTop = useCallback(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		})
	}, [])

	return <div className={style.bottomRightPosition}>
		{/* iOS Rendering Bug Workaround */}
		&nbsp;
		{children}
		{!scrolled100vh
			&& <Button variant="secondary"
				onClick={scrollToTop} title="Zum Anfang zurück">
				<FontAwesomeIcon icon={faArrowToTop} size="2x" fixedWidth={true} />
			</Button>}
	</div>
}

export default BottomRightCornerActions
