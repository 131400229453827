import {
	createAuthorizationHeader,
	contentTypeJson
} from "./dataHelper"
import { createReadOnlyItem } from "./itemHelper"
import User from "./models/User"
import Site from "./models/Site"
import Room from "./models/Room"
import ItemState from "./models/ItemState"
import Category from "./models/Category"
import Employee from "./models/Employee"
import ReadOnlyRoom from "./models/ReadOnlyRoom"
import SearchResult from "./models/SearchResults"
import ReadOnlyItemDataModel from "./models/ReadOnlyItem"
import EditItem from "./models/EditItem"
import EditedItem from "./models/EditedItem"
import EmployeeItems from "./models/EmployeeItems"
import SpecificStateItems from "./models/SpecificStateItems"
import { AccountInfo } from '@azure/msal-browser'
import { applicationinsights } from '../applicationInsights/applicationInsights'
import BeamerItems from "./models/BeamerItems"
import { redirectingErrorMessage } from "../authentication/PublicClientApplication"

interface RequiredRequestParameters {
	onRequestFailure: (dontShowError?: boolean) => unknown
	onRequestUnauthorized: () => unknown
	accountInfo: AccountInfo
}

const request = async <T>(
	request: Parameters<typeof fetch>,
	{
		accountInfo,
		onRequestUnauthorized,
		onRequestFailure
	}: /* Omit< */RequiredRequestParameters/* , "accountInfo"> */,
	result?: (response: Response) => Promise<T>,
	onlyCheckIfRequestUnauthorized: boolean = false) => {
	try {
		const authorizationHeader = await createAuthorizationHeader(accountInfo)

		const addedHeaders: [string, string][] = process.env.NODE_ENV === "development"
			// ? [authorizationHeader, ["ngrok-skip-browser-warning", "0"]]
			? [authorizationHeader, ["X-Tunnel-Authorization", "tunnel " + process.env.REACT_APP_DEVTUNNELTOKEN]]
			: [authorizationHeader]

		const requestParameters: Parameters<typeof fetch> = [
			request[0],
			request[1]
				? {
					...request[1],
					headers: request[1].headers
						? [...(request[1].headers instanceof Headers)
							? request[1].headers
							: request[1].headers instanceof Array
								? request[1].headers
								: Object.entries(request[1].headers)
							, ...addedHeaders]
						: addedHeaders
				}
				: undefined
		]

		const response = await fetch(...requestParameters)

		if (response.status === 401)
			onRequestUnauthorized()
		else if (!onlyCheckIfRequestUnauthorized && !response.ok)
			throw new Error(response.statusText)
		else if (result)
			return await result(response)
	} catch (error: unknown) {
		if (process.env.NODE_ENV === "production")
			applicationinsights.trackException({ exception: error as Error })

		onRequestFailure(
			error instanceof Error
			&& error.message === redirectingErrorMessage)
	}

	return null
}

export const getUser = async ({
	...requiredRequestParameters
}: RequiredRequestParameters,
	username: string) =>
	request(
		[`${process.env.REACT_APP_WEB_API}GetUser?username=${username}`,
		{
			method: "GET",
		}],
		requiredRequestParameters,
		async (response) => await response.json() as User)


export const search = async ({ ...requiredRequestParameters }: RequiredRequestParameters, searchTerm: string) =>
	request(
		[`${process.env.REACT_APP_WEB_API}Search?searchTerm=${searchTerm}`,
		{
			method: "GET",
		}],
		requiredRequestParameters,
		async (response) => await response.json() as SearchResult)


export const getEditItem = async ({ ...requiredRequestParameters }: RequiredRequestParameters, id: number) =>
	request(
		[`${process.env.REACT_APP_WEB_API}GetEditItem?id=${id}`,
		{
			method: "GET",
		}],
		requiredRequestParameters,
		async (response) => await response.json() as EditItem)


const getItemByIdentifier = async ({
	...requiredRequestParameters
}: RequiredRequestParameters,
	guid: string) => {
	return request([`${process.env.REACT_APP_WEB_API}GetItem?guid=${guid}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => response.ok
			? createReadOnlyItem(await response.json() as ReadOnlyItemDataModel)
			: null,
		true)
}

const getItemById = async ({
	...requiredRequestParameters
}: RequiredRequestParameters,
	id: number) => {
	return request([`${process.env.REACT_APP_WEB_API}GetItem?id=${id}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => createReadOnlyItem(await response.json() as ReadOnlyItemDataModel))
}

const getItemByInventoryNumber = async ({
	...requiredRequestParameters
}: RequiredRequestParameters,
	inventoryNumber: number) => {
	return request([`${process.env.REACT_APP_WEB_API}GetItemByInventoryNumber?inventoryNumber=${inventoryNumber}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) =>
			response.ok
				? createReadOnlyItem(await response.json() as ReadOnlyItemDataModel)
				: null,
		true)
}

export type ScannedItemRequestType =
	| {
		type: "guid"
		guid: string
	}
	| {
		type: "inventoryNumber"
		inventoryNumber: number
	}

type ItemRequestType = ScannedItemRequestType
	| {
		type: "id"
		id: number
	}

export const getItem = async (requiredRequestParameters: RequiredRequestParameters, itemRequestType: ItemRequestType) => {
	switch (itemRequestType.type) {
		case "guid":
			return await getItemByIdentifier(requiredRequestParameters, itemRequestType.guid)
		case "id":
			return await getItemById(requiredRequestParameters, itemRequestType.id)
		case "inventoryNumber":
			return await getItemByInventoryNumber(requiredRequestParameters, itemRequestType.inventoryNumber)
	}
}

export const getStates = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request([`${process.env.REACT_APP_WEB_API}GetStates`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as ItemState[])


export const getCategories = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request([`${process.env.REACT_APP_WEB_API}GetCategories`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as readonly Category[])


export const getStructure = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request([`${process.env.REACT_APP_WEB_API}GetStructure`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as readonly Site[])


export const getStorageRooms = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request([`${process.env.REACT_APP_WEB_API}GetStorageRooms`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as readonly Room[])


export const getRoom = async ({ ...requiredRequestParameters }: RequiredRequestParameters,
	roomId: number) =>
	request([`${process.env.REACT_APP_WEB_API}GetRoom?id=${roomId}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as Room)


export const getItemsInRoom = async ({ ...requiredRequestParameters }: RequiredRequestParameters,
	roomId: number) =>
	request([`${process.env.REACT_APP_WEB_API}GetItemsInRoom?roomId=${roomId}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as ReadOnlyRoom)


export const getItemsByStateId = async ({ ...requiredRequestParameters }: RequiredRequestParameters,
	stateId: number) =>
	request([`${process.env.REACT_APP_WEB_API}GetItemsByStateId?stateId=${stateId}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as SpecificStateItems)

export const getBeamerItems = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request(
		[`${process.env.REACT_APP_WEB_API}GetBeamerItems`,
		{
			method: "GET",
		}],
		requiredRequestParameters,
		async response => await response.json() as BeamerItems)

export const getRetiredEmployees = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request([`${process.env.REACT_APP_WEB_API}GetRetiredEmployees`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as readonly EmployeeItems[])

export const updateItem = async ({ ...requiredRequestParameters }: RequiredRequestParameters,
	editedItem: EditedItem) =>
	request([`${process.env.REACT_APP_WEB_API}UpdateItem`,
	{
		method: "POST",
		headers: [contentTypeJson],
		body: JSON.stringify(editedItem)
	}],
		requiredRequestParameters)

export const getEmployees = async ({ ...requiredRequestParameters }: RequiredRequestParameters) =>
	request(
		[`${process.env.REACT_APP_WEB_API}GetEmployees`,
		{
			method: "GET",
		}],
		requiredRequestParameters,
		async (response) => await response.json() as readonly Employee[])


export const getEmployee = async ({ ...requiredRequestParameters }: RequiredRequestParameters,
	employeeId: number) =>
	request([`${process.env.REACT_APP_WEB_API}GetEmployee?id=${employeeId}`,
	{
		method: "GET",
	}],
		requiredRequestParameters,
		async (response) => await response.json() as EmployeeItems)
