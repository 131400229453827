import {
	FC,
	useCallback,
	useState,
	useEffect,
	useRef
} from "react"
import QrReader from "react-qr-reader"
import {
	createWorker,
	Worker
} from "tesseract.js"
import { ScannedItemRequestType } from "../data/dataAccess"
import AzureOcrResponseBody from "../data/models/AzureOcrResponseBody"
import { canvasToBlobAsync } from "../data/utilityHelper"

interface Props {
	onScan: (itemRequestType: ScannedItemRequestType) => Promise<void>,
}

const azureOcrThrottlingLimit = 6 // * 0.5s

const parseInventoryNumber = (text: string) => parseFloat(/\d{12}/.exec(text)?.[0] ?? "")

const Scanner: FC<Props> = ({ onScan }) => {
	const [scanningStarted, setScanningStarted] = useState(false)
	const [mediaError, setMediaError] = useState(null as null | getUserMediaError)
	const [inventoryNumberScanner, setInventoryNumberScanner] = useState(null as null | Worker)

	// const [image, setImage] = useState(null as null | Blob)

	const azureOcrCounter = useRef(azureOcrThrottlingLimit)
	const firstSuccessfulScan = useRef(false)

	const container = useRef(null as null | HTMLDivElement)
	const preview = useRef(null as null | HTMLVideoElement)
	const canvas = useRef(null as null | HTMLCanvasElement)

	const onQRCodeScanned = useCallback(async (data: null | string) => {
		if (!scanningStarted)
			setScanningStarted(true)

		if (data && new RegExp(["{8}(-", "{4}){3}-", "{12}"].map(m => `${"[0-9A-Fa-f]"}${m}`).join("")).test(data)
			&& !firstSuccessfulScan.current) {
			firstSuccessfulScan.current = true
			await onScan({
				type: "guid",
				guid: data
			})
		}
	}, [onScan, scanningStarted, setScanningStarted])

	useEffect(() => {
		(async () => {
			const scanner = createWorker()
			await scanner.load()
			await scanner.loadLanguage("eng")
			await scanner.initialize("eng")
			await scanner.setParameters({
				tessedit_char_whitelist: [...new Array(10).keys()].map(m => m.toString()).join("")
			})

			setInventoryNumberScanner(scanner)
		})()
	}, [setInventoryNumberScanner])

	const azureOcrScan = useCallback(async (blob: Blob) => {
		const response = (await fetch("https://inventarnummererkennung.cognitiveservices.azure.com//vision/v2.0/ocr", {
			method: "POST",
			headers: [
				["content-type", "image/png"],
				["ocp-apim-subscription-key", process.env.REACT_APP_AZURE_COGNITIVE_SERVICE_KEY ?? ""]
			],
			body: blob
		}))

		if (response.ok) {
			const responseBody = (await response.json()) as AzureOcrResponseBody

			const inventoryNumber = parseInventoryNumber(responseBody.regions.flatMap(f => f.lines).flatMap(f => f.words).map(m => m.text).join())

			if (inventoryNumber && !firstSuccessfulScan.current) {
				return inventoryNumber
			}
		}

		return null
	}, [])

	const tesseractScan = useCallback(async (blob: Blob) => {
		if (inventoryNumberScanner) {
			const { data: { text } } = await inventoryNumberScanner.recognize(blob)

			const inventoryNumber = parseInventoryNumber(text)

			if (inventoryNumber && !firstSuccessfulScan.current) {
				return inventoryNumber
			}
		}

		return null
	}, [inventoryNumberScanner])

	const scan = useCallback(async () => {
		if (preview.current && canvas.current && inventoryNumberScanner) {
			canvas.current.width = preview.current.videoWidth
			canvas.current.height = preview.current.videoHeight

			const canvasContext = canvas.current.getContext("2d", { alpha: false })

			if (canvasContext) {
				canvasContext.drawImage(preview.current, 0, 0)
				const image = await canvasToBlobAsync(canvas.current)

				if (image) {
					if (azureOcrCounter.current === azureOcrThrottlingLimit) {
						azureOcrCounter.current = -1
					}

					azureOcrCounter.current++

					const inventoryNumber = await Promise.any([
						azureOcrCounter.current === 0 ? azureOcrScan(image) : undefined,
						tesseractScan(image)
					])

					if (inventoryNumber && !firstSuccessfulScan.current) {
						firstSuccessfulScan.current = true
						await onScan({
							type: "inventoryNumber",
							inventoryNumber
						})
					}
				}
			}
		}
	}, [inventoryNumberScanner, azureOcrScan, tesseractScan, onScan])

	useEffect(() => {
		if (inventoryNumberScanner && scanningStarted && container.current) {
			preview.current = container.current.querySelector("video")

			const intervalId = setInterval(scan, 500)
			return () => clearInterval(intervalId)
		}

		return () => { }
	}, [inventoryNumberScanner, scanningStarted, scan])

	const onError = useCallback((error: Error) => {
		setMediaError(error.name as getUserMediaError)
	}, [])

	return <div ref={container}>
		{mediaError === "NotAllowedError"
			? <>Damit der Scanner funktioniert, müssen Sie der Inventur App erlauben auf die rückseitige Kamera zuzugreifen!</>
			: mediaError === "NotFoundError" || mediaError !== null
				? <>Es wurde keine rückseitige Kamera gefunden!</>
				: <QrReader constraints={{
					facingMode: "environment",
					width: { ideal: 1920 },
					height: { ideal: 1080 }
				}} onScan={onQRCodeScanned} onError={onError} />}
		<canvas ref={canvas} hidden={true}></canvas>
	</div>
}

export default Scanner
