import {
	FC,
	useCallback,
	useContext,
	useReducer
} from "react"
import withAITracking from "../applicationInsights/applicationInsights"
import Scanner from "./Scanner"
import {
	ScannedItemRequestType
} from "../data/dataAccess"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { DataContext } from "../data/dataContext"
import {
	ScannerItemReducer,
	onScannerScan
} from "../data/ScannerItemReducer"
import ScannerRestart from "./ScannerRestart"
import ScannedItem from "./ScannedItem"
import style from "./style/ScannerItem.module.css"

const ScannerItem: FC = () => {

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure
	} = useContext(DataContext)

	const [state, dispatch] = useReducer(ScannerItemReducer, { renderScanner: true })

	const onScan = useCallback(async (itemRequestType: ScannedItemRequestType) => {
		await onScannerScan(itemRequestType,
			accountInfo,
			{
				onRequestUnauthorized,
				onRequestFailure,
			}, dispatch)
	}, [onRequestUnauthorized, onRequestFailure, accountInfo])

	return <div className={style.scannerItem}>
		{state.renderScanner
			? <Scanner onScan={onScan} />
			: <>
				<ScannerRestart scannerItemDispatch={dispatch} />
				<ScannedItem scannerState={state} />
			</>
		}
	</div>
}

export default withAITracking(ScannerItem, "ScannerItem")