export const rootPath = "/"

export const searchResultsPath = "/searchResults"
export const searchTermParameter = "query"

export const scannerItemPath = "/scannerItem"
export const itemEditorPath = "/itemEditor"
export const itemPath = "/item"
export const itemIdParameter = "id"

export const itemsByStatePath = "/items"
export const itemsByStateStateIdParameter = "stateId"
export const retiredEmployees = "/retiredEmployees"
export const beamerItems = "/beamer"

export const roomsPath = "/rooms"
export const roomPath = "/room"
export const roomIdParameter = "id"

export const employeesPath = "/employees"
export const employeePath = "/employee"
export const employeeIdParameter = "employeeId"
