// https://www.npmjs.com/package/react-devtools#usage-with-react-dom
// import "react-devtools"
import {
	StrictMode
} from "react"
import { render } from "react-dom"
import * as serviceWorker from "./serviceWorker"
import Authentication from "./components/Authentication"
import "./style/index.scss"

render(
	<StrictMode>
		<Authentication />
	</StrictMode>,
	document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()