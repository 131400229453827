///<reference path="../ambientModules.d.ts">
import {
	FC,
	useEffect,
	useReducer,
	useContext,
	useState,
	useCallback,
} from "react"
import {
	Routes,
	Route,
	useLocation,
	useNavigate
} from "react-router-dom"
import DropdownButton from "react-bootstrap/DropdownButton"
import DropDownItem from "react-bootstrap/DropdownItem"
import NavigationMobile from "./NavigationMobile"
import {
	slide as Menu,
	State as MenuState
} from "react-burger-menu"
import { useInView } from "react-intersection-observer"
import withApplicationInsights from "../applicationInsights/applicationInsights"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { DataContext } from "../data/dataContext"
import { ScrolledDownContext } from "../data/scrolledDownContext"
import { MissingPhotoEmployeeIds } from "../data/missingPhotoEmployeeIds"
import ItemRoomSelection from "./ItemRoomSelection"
import Header from "./Header"
import ScannerItem from "./ScannerItem"
import Item from "./Item"
import RoomDetails from "./RoomDetails"
import EmployeesFilteredSelection from "./EmployeesFilteredSelection"
import {
	getUser,
	getEmployees,
	getStructure,
	getCategories,
	getStates,
	getStorageRooms
} from "../data/dataAccess"
import {
	searchResultsPath,
	searchTermParameter,
	scannerItemPath,
	itemPath,
	itemEditorPath,
	itemIdParameter,
	roomsPath,
	roomIdParameter,
	roomPath,
	employeesPath,
	employeeIdParameter,
	employeePath,
	rootPath,
	itemsByStatePath,
	itemsByStateStateIdParameter,
	retiredEmployees,
	beamerItems
} from "../routes/routingPaths"
import EmployeeDetails from "./EmployeeDetails"
import SearchResults from "./SearchResults"
import ItemEditor from "./ItemEditor"
import Search from "./Search"
import ItemsByState from "./ItemsByState"
import ItemsWithSameStateLinksFactory, { rma, notFound, auszuscheiden, takenOverByHR } from "./ItemsWithSameStateLinks"
import Employee from "../data/models/Employee"
import Site from "../data/models/Site"
import Category from "../data/models/Category"
import ItemState from "../data/models/ItemState"
import {
	RoomSelectionReducer,
	defaultRoomSelectionState,
	initialRoomSelectionState
} from "../data/RoomSelectionReducer"
import Room from "../data/models/Room"
import { getSurnameFilteredEmployees } from "../data/dataHelper"
import { isDesktop } from "../data/styleHelper"
import EmployeeSurnameFilter from "./EmployeeSurnameFilter"
import Employees from "./Employees"
import { navigateToEmployee } from "../routes/routingFunctions"
import { ScannerItemReducer } from "../data/ScannerItemReducer"
import ScannedItem from "./ScannedItem"
import BottomRightCornerActions from "./BottomRightCornerActions"
import ScannerItemDesktop from "./ScannerItemDesktop"
import style from "./style/Inventur.module.scss"
import RetiredEmployees from './RetiredEmployees'
import RetiredEmployeesLink from './RetiredEmployeesLink'
import { publicClientApplication } from '../authentication/PublicClientApplication'
import User from '../data/models/User'
import BeamerItemsComponent from "./BeamerItems"
import { beamerNavigationText } from "../data/itemHelper"

interface State {
	WebApiRequestUnauthorized: boolean
	WebApiRequestFailure: boolean
	user: null | User
	employees: readonly Employee[]
	structure: readonly Site[]
	storageRooms: readonly Room[]
	categories: readonly Category[]
	itemStates: readonly ItemState[],
}

type Action =
	| {
		type: "WebApiRequestAuthorized"
		payload: {
			user: User
			employees: readonly Employee[]
			structure: readonly Site[]
			storageRooms: readonly Room[]
			categories: readonly Category[]
			itemStates: readonly ItemState[]
		}
	}
	| { type: "WebApiRequestUnauthorized" }
	| { type: "WebApiRequestError" }

const inventurReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "WebApiRequestAuthorized":
			return {
				...state,
				user: action.payload.user,
				employees: action.payload.employees,
				structure: action.payload.structure,
				storageRooms: action.payload.storageRooms,
				categories: action.payload.categories,
				itemStates: action.payload.itemStates
			}
		case "WebApiRequestUnauthorized":
			return {
				...state,
				WebApiRequestUnauthorized: true
			}
		case "WebApiRequestError":
			return {
				...state,
				WebApiRequestFailure: true
			}
	}
}

const Inventur: FC = () => {
	const { accountInfo } = useContext(AuthenticationContext)

	const location = useLocation()

	const navigation = useNavigate()

	const [intersectionObserverTarget, intersectionObserverTargetIsInView] = useInView({
		rootMargin: "100% 0px 0px 0px"
	})

	//#region web api failures

	const onRequestUnauthorized = useCallback(() => dispatch({ type: "WebApiRequestUnauthorized" }), [])

	const onRequestFailure = useCallback((dontShowError?: boolean) => {
		if (!dontShowError)
			dispatch({ type: "WebApiRequestError" })
	}, [])

	//#endregion

	const [state, dispatch] = useReducer(inventurReducer, {
		WebApiRequestUnauthorized: false,
		WebApiRequestFailure: false,
		user: null,
		employees: [],
		structure: [],
		storageRooms: [],
		categories: [],
		itemStates: []
	} as State)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const [user,
					employees,
					structure,
					storageRooms,
					itemCategories,
					itemStates] = (await Promise.all([
						getUser(
							{ onRequestUnauthorized, onRequestFailure, accountInfo },
							// uncomment if staging db still has production data
							/* process.env.NODE_ENV === "development"
								? !accountInfo.username.includes(".admin@")
									? accountInfo.username
									: accountInfo.name?.replace(" ", ".").split("@").at(0) + "@fh-krems.ac.at"
								:  */accountInfo.username),
						getEmployees({ onRequestUnauthorized, onRequestFailure, accountInfo }),
						getStructure({ onRequestUnauthorized, onRequestFailure, accountInfo }),
						getStorageRooms({ onRequestUnauthorized, onRequestFailure, accountInfo }),
						getCategories({ onRequestUnauthorized, onRequestFailure, accountInfo }),
						getStates({ onRequestUnauthorized, onRequestFailure, accountInfo })]))

				if (user && employees && structure && storageRooms
					&& itemCategories && itemStates) {
					dispatch({
						type: "WebApiRequestAuthorized",
						payload: {
							user,
							employees,
							structure,
							storageRooms,
							categories: itemCategories,
							itemStates
						}
					})
				}
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, accountInfo])

	//#region menu

	const [menuIsOpen, setMenuIsOpen] = useState(false)

	const onMenuOpen = useCallback(() => setMenuIsOpen(true), [])

	const onMenuStateChange = useCallback(({ isOpen }: MenuState) =>
		setMenuIsOpen(isOpen), [])

	//#endregion

	const [missingPhotoEmployeeIds, setMissingPhotoEmployeeIds] = useState(new Set<number>())

	const [scannerItemState, scannerItemDispatch] = useReducer(ScannerItemReducer, { renderScanner: false })

	const [employeeSurnameFirstLetterFilter, setEmployeeSurnameFirstLetterFilter] = useState("")

	const [roomSelectionState, roomSelectionDispatch] = useReducer(RoomSelectionReducer, { ...defaultRoomSelectionState, ...initialRoomSelectionState })

	const sharedRoutes = [
		{ path: `${searchResultsPath}/:${searchTermParameter}`, element: <SearchResults /> },
		{ path: `${roomPath}/:${roomIdParameter}`, element: <RoomDetails /> },
		{ path: `${employeePath}/:${employeeIdParameter}`, element: <EmployeeDetails /> },
		{ path: `${itemsByStatePath}/:${itemsByStateStateIdParameter}`, element: <ItemsByState /> },
		{ path: beamerItems, element: <BeamerItemsComponent /> },
		{ path: retiredEmployees, element: <RetiredEmployees /> },
		{ path: `${itemPath}/:${itemIdParameter}`, element: <Item /> },
		{ path: `${itemEditorPath}/:${itemIdParameter}`, element: <ItemEditor /> },
	].map((route, index) => <Route key={index} path={route.path} element={route.element} />)

	return <>
		<div ref={intersectionObserverTarget} className={style.intersectionObserverTarget}></div>
		<Menu width={200} customBurgerIcon={false} isOpen={menuIsOpen} right={true} id="menu"
			onStateChange={onMenuStateChange} menuClassName={style.menu}
			crossClassName={style.sideBarClose} itemClassName={style.item}>
			<DropdownButton id="user" title={state.user ? `${state.user.Name} ${state.user.Surname}` : ""}>
				<DropDownItem as="button" onClick={() => publicClientApplication.logout()}>
					Ausloggen
				</DropDownItem>
			</DropdownButton>
		</Menu>
		<div className={isDesktop ? style.containerDesktop : style.container}>
			<header className={style.header}>
				<Header onMenuOpen={onMenuOpen} />
			</header>
			{!state.WebApiRequestUnauthorized
				&& !state.WebApiRequestFailure
				&& state.user
				&& <DataContext.Provider value={{
					onRequestUnauthorized,
					onRequestFailure,
					userEmployeeId: state.user.Id,
					employees: state.employees,
					structure: state.structure,
					storageRooms: state.storageRooms,
					itemCategories: state.categories,
					itemStates: state.itemStates,
				}}>
					<ScrolledDownContext.Provider value={intersectionObserverTargetIsInView}>
						<MissingPhotoEmployeeIds.Provider value={{
							missingPhotoEmployeeIds,
							setMissingPhotoEmployeeIds
						}}>
							{isDesktop
								? <>
									<ItemsWithSameStateLinksFactory link={rma}
										className={style.desktopHeaderItem} />
									<ItemsWithSameStateLinksFactory link={
										{
											name: beamerNavigationText,
											path: beamerItems
										}}
										className={style.desktopHeaderItem} />
									{[notFound, auszuscheiden, takenOverByHR]
										.map(m => <ItemsWithSameStateLinksFactory
											key={m.path}
											link={m}
											className={style.desktopHeaderItem} />)}
									<RetiredEmployeesLink className={style.desktopHeaderItem} />
									<Search className={style.desktopHeaderItem} />
									<div className={style.desktopFilters}>
										<ScannerItemDesktop state={scannerItemState} dispatch={scannerItemDispatch} />
										<EmployeeSurnameFilter
											selectedEmployeeSurnameFirstLetter={location.pathname === employeesPath
												? employeeSurnameFirstLetterFilter
												: ""}
											onFilter={employeeId => {
												setEmployeeSurnameFirstLetterFilter(employeeId)
												navigation(employeesPath)
											}} />
										<ItemRoomSelection state={roomSelectionState} dispatch={roomSelectionDispatch} />
									</div>
									<div className={style.desktopContent}>
										<Routes>
											{sharedRoutes}
											<Route path={scannerItemPath} element={<ScannedItem scannerState={scannerItemState} />} />
											<Route path={employeesPath} element={
												<>
													<Employees
														employees={getSurnameFilteredEmployees(employeeSurnameFirstLetterFilter, state.employees)}
														onEmployeeClick={employeeId => navigateToEmployee(navigation, employeeId)} />
													<BottomRightCornerActions />
												</>
											} />
										</Routes>
									</div>
								</>
								: <>
									{location.pathname !== scannerItemPath
										? <div className={`${location.pathname === rootPath
											? style.searchTopMarginInRootOnly
											: ""}`}>
											<Search />
										</div>
										: null}
									<div>
										<Routes>
											<Route path={rootPath} element={<NavigationMobile roomSelectionDispatch={roomSelectionDispatch} />} />
											{sharedRoutes}
											<Route path={scannerItemPath} element={<ScannerItem />} />
											<Route path={roomsPath} element={
												<>
													<ItemRoomSelection state={roomSelectionState} dispatch={roomSelectionDispatch} />
													<BottomRightCornerActions />
												</>
											} />
											<Route path={employeesPath} element={<EmployeesFilteredSelection />} />
										</Routes>
									</div>
								</>
							}
						</MissingPhotoEmployeeIds.Provider>
					</ScrolledDownContext.Provider>
				</DataContext.Provider>
			}
		</div>
		{state.WebApiRequestUnauthorized
			&& <div className={style.webApiRequestError}>
				Sie sind leider nicht berechtigt die Inventur App zu verwenden!
			</div>}
		{state.WebApiRequestFailure
			&& <div className={style.webApiRequestError}>
				Es ist leider beim Abrufen der Daten ein Fehler aufgetreten!
			</div>}
	</>
}

export default withApplicationInsights(Inventur, "Inventur")
