import { FC } from "react"
import { retiredEmployees } from "../routes/routingPaths"
import Button from "react-bootstrap/Button"
import { LinkContainer } from "react-router-bootstrap"
import { isDesktop } from "../data/styleHelper"

interface Props {
	className?: string
}

const RetiredEmployeesLink: FC<Props> = ({ className }) =>
	<LinkContainer to={retiredEmployees} className={className}>
		<Button size={isDesktop ? "lg" : undefined}>
			Austritt
		</Button>
	</LinkContainer>

export default RetiredEmployeesLink
