import Employee from "./models/Employee"
import { AccountInfo } from '@azure/msal-browser'
import { getTokenRedirect } from '../authentication/PublicClientApplication'

export const europeVienna = "Europe/Vienna"

export const createAuthorizationHeader = async (accountInfo: AccountInfo): Promise<[string, string]> =>
	["Authorization", `Bearer ${await getTokenRedirect(accountInfo)}`]

export const contentTypeJson: [string, string] = ["content-type", "application/json"]

export const getSurnameFilteredEmployees = (employeeSurnameFirstLetterFilter: string, employees: readonly Employee[]) =>
	employees.filter(f => (employeeSurnameFirstLetterFilter.length > 0
		? f.Surname.charAt(0).toLowerCase() === employeeSurnameFirstLetterFilter.toLowerCase()
		: true))
