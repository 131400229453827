import React, {
	FC,
	useEffect,
	useContext,
	useCallback,
	useState,
} from "react"
import {
	useParams,
	useNavigate
} from "react-router"
import { itemsByStateStateIdParameter } from "../routes/routingPaths"
import { navigateToItem } from "../routes/routingFunctions"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import BottomRightCornerActions from "./BottomRightCornerActions"
import Share from "./Share"
import { getItemsByStateId } from "../data/dataAccess"
import { DataContext } from "../data/dataContext"
import { CreateCategoryGroupedItemsShareText, rmaRoomId, rmaStateId } from "../data/itemHelper"
import ItemRecordComponent from "./ItemRecordComponent"
import SpecificStateItems from '../data/models/SpecificStateItems'
import EmployeeItemsList from './EmployeeItemsList'
import SplittedList from './SplittedList'
import RoomItems from '../data/models/RoomItems'
import style from "./style/ItemsByState.module.css"

const noRoomName = "kein Raum"

const ItemsByState: FC = () => {
	const stateId = Number((useParams())[itemsByStateStateIdParameter])

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		itemStates,
		itemCategories,
		onRequestFailure,
		onRequestUnauthorized,
		storageRooms
	} = useContext(DataContext)

	const navigation = useNavigate()

	const [items, setItems] = useState(null as null | SpecificStateItems)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const response = await getItemsByStateId({
					accountInfo,
					onRequestFailure,
					onRequestUnauthorized
				}, stateId)

				if (response) {
					setItems(response)
				}
			}
		})()
	}, [accountInfo, onRequestFailure, onRequestUnauthorized, stateId])

	const itemStateName = itemStates.find(f => f.Id === stateId)?.Name

	const onShare = useCallback(() => {
		if (items && navigator.share) {
			navigator.share({
				text: `${itemStateName}:\n
				${items.Employees.map(m =>
					`Mitarbeiter: ${m.Surname} ${m.Name}\n
					${CreateCategoryGroupedItemsShareText(itemCategories, m.Items)}`).join("\n\n\n")}\n
				${`${items.SiteItems.map(site =>
						`${site.Site}\n\n${site.RoomRessourceItems.map(room =>
							`${room.Room?.Name ?? noRoomName}\n\n${CreateCategoryGroupedItemsShareText(itemCategories, room.Items)}`)
							.join("\n\n")}`).join("\n\n")}`}`.split("\n").map(m => m.trim()).join("\n")
			})
		}
	}, [itemCategories, items, itemStateName])

	const isRmaStateId = stateId === rmaStateId

	return <>
		<h1>
			{itemStateName}
		</h1>
		{items
			&& <div className={`${style.fullWidthLayout} ${style.gridBigGap}`}>
				{items.Employees.length > 0
					&& <>
						<h2>Mitarbeiter</h2>
						<EmployeeItemsList employees={items.Employees} />
					</>
				}
				<div className={`${style.fullWidthLayout} ${style.gridBigGap}`}>
					{items.SiteItems.length > 0
						&& <>
							{isRmaStateId
								? <h2>{storageRooms.find(f => f.Id === rmaRoomId)?.Name}</h2>
								: null}
							{items.SiteItems.map(m => <React.Fragment key={m.Site}>
								{!isRmaStateId
									? <h2>{m.Site}</h2>
									: null}
								<SplittedList<RoomItems>
									list={m.RoomRessourceItems}
									getKey={roomItems => roomItems.Room?.Id ?? 0}
									itemRender={roomItems =>
										<div className={`${style.fullWidthLayout} ${style.gridSmallGap}`}>
											{roomItems.Items.map(item =>
												<ItemRecordComponent
													key={item.Id}
													item={item}
													onClick={itemId => navigateToItem(navigation, itemId)}>
													{
														roomItems.Room
															? roomItems.Room.Name
															: noRoomName
													}
												</ItemRecordComponent>)}
										</div>
									}
								/>
							</React.Fragment>)}
						</>}
					{/* {items.RoomRessourceItems.length > 0
						&& <>
							<h2>
								{roomResource}
							</h2>
							<SplittedList<RoomItems> list={items.RoomRessourceItems} getKey={roomItems => roomItems.Room?.Id ?? 0}
								itemRender={roomItems =>
									<div className={`${style.fullWidthLayout} ${style.gridSmallGap}`}>
										{roomItems.Room
											? <h3>{roomItems.Room.Name}</h3>
											: <h3>{noRoomName}</h3>}
										{roomItems.Items.map(item =>
											<ItemRecordComponent key={item.Id} item={item} onClick={itemId => navigateToItem(navigation, itemId)} />)}
									</div>
								}
							/>
						</>
					} */}
				</div>
			</div>
		}
		<BottomRightCornerActions>
			<Share onShare={onShare} title="teile Artikelliste" />
		</BottomRightCornerActions>
	</>
}

export default ItemsByState
