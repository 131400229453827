import {
	ScannedItemRequestType,
	getItem
} from "./dataAccess"
import ReadOnlyItem from "../appmodels/ReadOnlyItem"
import { AccountInfo } from '@azure/msal-browser'

export interface ScannerItemState {

	renderScanner: boolean
	item?: ReadOnlyItem
	itemRequestType?: ScannedItemRequestType
}

export type ScannerItemAction =
	| {
		type: "setItem"
		payload: {
			item: ReadOnlyItem
		}
	}
	| { type: "rescan" }

export const ScannerItemReducer = (state: ScannerItemState, action: ScannerItemAction): ScannerItemState => {
	switch (action.type) {
		case "setItem":
			return {
				renderScanner: false,
				item: action.payload.item,
			}
		case "rescan": {
			return {
				...state,
				renderScanner: true,
				item: undefined
			}
		}
	}
}

export const onScannerScan = async (
	itemRequestType: ScannedItemRequestType,
	accountInfo: null | AccountInfo,
	requestParameters: Omit<Parameters<typeof getItem>[0], "accountInfo">,
	dispatch: (value: ScannerItemAction) => void) => {
	if (accountInfo) {
		const getItemResponse = await getItem(
			{
				...requestParameters,
				accountInfo
			}, itemRequestType)

		if (getItemResponse) {
			dispatch({
				type: "setItem",
				payload: {
					item: getItemResponse
				}
			})
		}
	}
}
