import {
	FC,
	useCallback,
	useRef,
	useContext
} from "react"
import { useInView } from "react-intersection-observer"
import Employee from "../data/models/Employee"
import { MissingPhotoEmployeeIds } from "../data/missingPhotoEmployeeIds"
import { imageLazyLoadingSupported } from "../data/utilityHelper"
import style from "./style/EmployeePhoto.module.css"

interface Props {
	employee: Employee,
}

const defaultPhoto = "https://www.fh-krems.ac.at/fileadmin/public/staff/profile-coming-soon.jpg"

const EmployeePhoto: FC<Props> = ({ employee }) => {
	const photo = useRef(null as null | HTMLImageElement)

	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: "10%"
	})

	const {
		missingPhotoEmployeeIds,
		setMissingPhotoEmployeeIds
	} = useContext(MissingPhotoEmployeeIds)

	const onPhotoNotAvailable = useCallback(() => {
		if (photo.current) {
			photo.current.src = defaultPhoto
		}
		setMissingPhotoEmployeeIds(missingPhotoEmployeeIds.add(employee.Id))
	}, [setMissingPhotoEmployeeIds, missingPhotoEmployeeIds, employee.Id])

	//https://github.com/thebuilder/react-intersection-observer/blob/master/docs/Recipes.md#lazy-image-load
	return <span
		ref={!imageLazyLoadingSupported ? ref : undefined}>
		<img ref={photo}
			src={(imageLazyLoadingSupported || inView) && !missingPhotoEmployeeIds.has(employee.Id)
				? `https://www.fh-krems.ac.at/fileadmin/public/staff/web.${employee.Username.toLowerCase()}.jpg`
				: defaultPhoto}
			alt={`${employee.Surname} ${employee.Name}`}
			className={`${style.employeePhoto}`}
			loading="lazy"
			onError={onPhotoNotAvailable} />
	</span>
}

export default EmployeePhoto
