import {
	FC,
	useEffect,
	useContext,
	useState,
	useCallback,
} from "react"
import {
	useParams,
	useNavigate
} from "react-router-dom"
import { roomIdParameter } from "../routes/routingPaths"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { getItemsInRoom } from "../data/dataAccess"
import ReadOnlyRoom from "../data/models/ReadOnlyRoom"
import { DataContext } from "../data/dataContext"
import withAITracking from "../applicationInsights/applicationInsights"
import Share from "./Share"
import { CreateCategoryGroupedItemsShareText } from "../data/itemHelper"
import SplittedList from "./SplittedList"
import ItemRecord from "../data/models/ItemRecord"
import ItemRecordComponent from "./ItemRecordComponent"
import BottomRightCornerActions from "./BottomRightCornerActions"
import { navigateToItem } from "../routes/routingFunctions"
import style from "./style/RoomDetails.module.css"
import EmployeeItemsList from "./EmployeeItemsList"

const RoomDetails: FC = () => {
	const id = Number((useParams())[roomIdParameter])

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure,
		itemCategories
	} = useContext(DataContext)

	const navigation = useNavigate()

	const [room, setRoom] = useState({
		Site: "",
		Name: "",
		Type: "",
		Employees: [],
		RoomItems: [],
		Items: [],
		IsStorageRoom: false,
		ItemGroups: []
	} as ReadOnlyRoom)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const response = await getItemsInRoom({
					onRequestUnauthorized,
					onRequestFailure,
					accountInfo
				}, id)
				if (response)
					setRoom(response)
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, accountInfo, id])

	const onShare = useCallback(() => {
		(async () => {
			if (navigator.share) {
				const text = `${room.Site} ${room.Name}
				${room.Employees.length > 0
						? `${room.Employees.map((employee, i) =>
							`${i > 0 ? "\n" : ""}\nMitarbeiter: ${employee.Surname} ${employee.Name}\n
						${CreateCategoryGroupedItemsShareText(itemCategories, employee.Items)}`).join("\n")}\n`
						: ""}
				Raumressource:\n
				${CreateCategoryGroupedItemsShareText(itemCategories, room.RoomItems)}\n\n
				freie Ressource:\n
				${CreateCategoryGroupedItemsShareText(itemCategories, room.Items)}
				${room.IsStorageRoom ? "Lager\n" : ""}
				${room.ItemGroups.map(itemGroup =>
							`${itemGroup.Name}\n
					${CreateCategoryGroupedItemsShareText(itemCategories, itemGroup.Items)}`).join("\n\n")}`
					.split("\n").map(m => m.trim()).join("\n")

				await navigator.share({
					url: `${window.location.href}`,
					text
				})
			}
		})()
	}, [itemCategories, room.ItemGroups, room.IsStorageRoom, room.Employees, room.RoomItems,
		room.Items, room.Name, room.Site])

	return <div className={style.layout}>
		<div>
			<h1>{room.Site} {room.Name}</h1>
			<h3>{room.Type}</h3>
		</div>
		{room.Employees.length > 0
			&& <section>
				<h2>
					Aktiv
				</h2>
				<EmployeeItemsList employees={room.Employees} />
			</section>
		}
		{!room.IsStorageRoom && [room.RoomItems, room.Items].some(s => s.length > 0)
			&& <>
				{([["Raumressource", room.RoomItems],
				["freie Ressource", room.Items]] as const)
					.filter(f => f[1].length > 0)
					.map(m =>
						<section key={m[0]}>
							<h2>
								{m[0]}
							</h2>
							<div>
								<SplittedList<ItemRecord> list={m[1]}
									getKey={i => i.Id}
									itemRender={item =>
										<ItemRecordComponent item={item}
											onClick={itemId => navigateToItem(navigation, itemId)} />
									}
								/>
							</div>
						</section>
					)}
			</>
		}
		{room.IsStorageRoom
			&& room.ItemGroups.map(m =>
				<section key={m.Name}>
					<h2>{m.Name}</h2>
					<div>
						<SplittedList<ItemRecord> list={m.Items}
							getKey={i => i.Id}
							itemRender={item =>
								<ItemRecordComponent item={item}
									onClick={itemId => navigateToItem(navigation, itemId)} />
							}
						/>
					</div>
				</section>)
		}
		<BottomRightCornerActions>
			{[room.Employees, room.RoomItems, room.Items, room.ItemGroups].some(s => s.length > 0)
				&& <Share title="teile Raum" onShare={onShare} />}
		</BottomRightCornerActions>
	</div>
}

export default withAITracking(RoomDetails, "RoomDetails")
