import {
	FC,
	useEffect,
	useState,
	useContext
} from "react"
import {
	useParams,
	useNavigate
} from "react-router-dom"
import {
	searchTermParameter,
	roomPath,
	employeePath,
	itemPath
} from "../routes/routingPaths"
import withAITracking from "../applicationInsights/applicationInsights"
import { search } from "../data/dataAccess"
import SearchResult from "../data/models/SearchResults"
import Employee from "../data/models/Employee"
import Room from "../data/models/Room"
import SearchResultItem from "../data/models/SearchResultItem"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import RoomRecord from "./RoomRecord"
import SearchResultItemComponent from "./SearchResultItemComponent"
import SearchResultValue from "./SearchResultValue"
import SplittedList from "./SplittedList"
import EmployeeRecord from "./EmployeeRecord"
import BottomRightCornerActions from "./BottomRightCornerActions"
import { DataContext } from "../data/dataContext"
import {
	navigateToItem,
	navigateToEmployee,
	navigateToRoom
} from "../routes/routingFunctions"
import style from "./style/SearchResults.module.css"

const pathsMap = new Map<keyof SearchResult, string>([
	["Employees", employeePath],
	["Rooms", roomPath],
	["Items", itemPath]])

const SearchResults: FC = () => {
	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure
	} = useContext(DataContext)

	const searchTerm = (useParams())[searchTermParameter] ?? ""

	const navigation = useNavigate()

	const [searchResult, setSearchResult] = useState({
		Employees: [],
		Rooms: [],
		Items: []
	} as SearchResult)

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		(async () => {
			if (accountInfo) {

				setIsLoading(true)

				const searchResultsResponse = await search({
					onRequestUnauthorized,
					onRequestFailure,
					accountInfo
				}, searchTerm)

				if (searchResultsResponse) {
					const iteratableSearchResults = Object.entries(searchResultsResponse) as unknown as [keyof SearchResult, (Employee | Room | SearchResultItem)[]][]

					for (const pathTuple of pathsMap) {
						const categorySearchResults = iteratableSearchResults.find(f => f[0] === pathTuple[0])?.[1]
						if (categorySearchResults?.length === 1
							&& iteratableSearchResults.filter(f => f[0] !== pathTuple[0]).every(e => e[1].length === 0)) {
							navigation(`${pathTuple[1]}/${categorySearchResults[0]?.Id}`)
						}
					}

					setSearchResult(searchResultsResponse)
					setIsLoading(false)
				}
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, accountInfo, navigation, searchTerm])

	const itemSearchResults: {
		searchValueCategory: string,
		items: SearchResultItem[],
		getValue: (item: SearchResultItem) => string | number
	}[] = [
			{
				searchValueCategory: "Artikel",
				items: searchResult.Items.filter(f => f.Name.toLowerCase().includes(searchTerm.toLowerCase())), getValue: item => item.Name
			},
			{
				searchValueCategory: "Gerätename",
				items: searchResult.Items.filter(f => f.DeviceName?.length),
				getValue: item => item.DeviceName ?? ""
			},
			{
				searchValueCategory: "Inventarnummer",
				items: searchResult.Items.filter(f => f.InventoryNumber),
				getValue: item => item.InventoryNumber ?? ""
			},
			{
				searchValueCategory: "Seriennummer",
				items: searchResult.Items.filter(f => f.SerialNumber?.length),
				getValue: item => item.SerialNumber ?? ""
			},
			{
				searchValueCategory: "IMEI",
				items: searchResult.Items.filter(f => f.Imei),
				getValue: item => item.Imei ?? ""
			}
		]

	return <>
		<h1>"{searchTerm}"</h1>
		{isLoading
			? <></>
			: ([searchResult.Employees,
			searchResult.Items,
			searchResult.Rooms] as const)
				.some(s => s.length > 0)
				? <div className={style.searchResultCategories}>
					{searchResult.Employees.length > 0
						? <section>
							<h2>Mitarbeiter</h2>
							<SplittedList<Employee> list={searchResult.Employees}
								getKey={e => e.Id}
								itemRender={i => <EmployeeRecord employee={i}
									onClick={employeeId => navigateToEmployee(navigation, employeeId)}
									customEmployeeName={employee =>
										<>
											<span>
												<SearchResultValue value={employee.Surname} searchTerm={searchTerm} />
											</span>
											&nbsp;
											<span>
												<SearchResultValue value={employee.Name} searchTerm={searchTerm} />
											</span>
										</>
									}
								/>}
							/>
						</section>
						: null}
					{searchResult.Rooms.length > 0
						? <section>
							<h2>Raum</h2>
							<div>
								{searchResult.Rooms.map(m =>
									<span key={m.Id} className={style.room}>
										<RoomRecord room={m} onClick={roomId => navigateToRoom(navigation, roomId)}
											customRoomName={room =>
												<SearchResultValue value={room.Name} searchTerm={searchTerm} />}
										/>
									</span>
								)}
							</div>
						</section>
						: null}
					{itemSearchResults.map(m => m.items.length > 0
						? <section key={m.searchValueCategory}>
							<h2>{m.searchValueCategory}</h2>
							<div>
								<SplittedList<SearchResultItem> list={m.items} getKey={i => i.Id}
									itemRender={item =>
										<SearchResultItemComponent item={item} searchTerm={searchTerm}
											itemProperty={m.getValue(item)} onClick={itemId => navigateToItem(navigation, itemId)} />
									}
								/>
							</div>
						</section>
						: null
					)}
				</div>
				: <h1>keine Suchergebnisse gefunden</h1>
		}
		<BottomRightCornerActions />
	</>
}

export default withAITracking(SearchResults, "SearchResults")
