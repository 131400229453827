import {
	FC,
	useState
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faChevronDown,
	faChevronUp
} from "@fortawesome/pro-solid-svg-icons"
import { Collapse } from "react-collapse"
import ItemRecordComponent from "./ItemRecordComponent"
import EmployeeRecord from "./EmployeeRecord"
import EmployeeItems from "../data/models/EmployeeItems"
import style from "./style/EmployeeItems.module.css"

interface Props {
	employee: EmployeeItems
	onEmployeeClick: (employeeId: number) => void
	onItemClick: (itemId: number) => void,
}

const EmployeeItemsComponent: FC<Props> = ({
	employee,
	onEmployeeClick,
	onItemClick
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return <div className={style.employeeItemsComponent}>
		<EmployeeRecord employee={employee}
			customLastRow={
				<span className={style.employeeItemsOpenerIcon} onClick={() => setIsOpen(!isOpen)}
					title="Schalte Artikelliste">
					<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
				</span>
			}
			variant={isOpen ? "secondary" : "primary"}
			onClick={employeeId => onEmployeeClick(employeeId)} />
		<Collapse isOpened={isOpen} theme={{
			collapse: style.employeeItemsContainer,
			content: style.employeeItems
		}}>
			{employee.Items.map(item =>
				<ItemRecordComponent key={item.Id} item={item} onClick={itemId => onItemClick(itemId)} />
			)}
		</Collapse>
	</div>
}

export default EmployeeItemsComponent
