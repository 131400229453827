import {
	FC,
	useCallback,
	useContext,
	Dispatch
} from "react"
import Button from "react-bootstrap/Button"
import { DataContext } from "../data/dataContext"
import {
	RoomSelectionState,
	defaultRoomSelectionState,
	RoomSelectionAction
} from "../data/RoomSelectionReducer"
import style from "./style/RoomSelection.module.css"
import { isDesktop } from '../data/styleHelper'

const twoColumnMobileRoomLayoutSiteIds: ReadonlySet<number> = new Set([8, 9])

interface Props {
	state: RoomSelectionState
	dispatch: Dispatch<RoomSelectionAction>
	onRoomSelected: (roomId: number) => void,
}

const RoomSelection: FC<Props> = ({
	state,
	dispatch,
	onRoomSelected
}) => {
	const { structure } = useContext(DataContext)

	const onlyOneBuildingInSite = useCallback((siteId: number) =>
		siteId !== defaultRoomSelectionState.siteId
		&& structure.find(f => f.Id === siteId)?.Buildings.length === 1,
		[structure])

	const onSiteChange = useCallback((siteId: number) =>
		dispatch({
			type: "siteChanged",
			payload: {
				siteId,
				buildingId: onlyOneBuildingInSite(siteId)
					? structure.find(f => f.Id === siteId)?.Buildings[0]?.Id ?? defaultRoomSelectionState.buildingId
					// wenn standort campus, dann ist trakt g vorausgewählt
					: siteId === 2 && state.siteId !== 2
						? 14
						: defaultRoomSelectionState.buildingId
			}
		}), [dispatch, structure, onlyOneBuildingInSite, state.siteId])

	const onBuildingChange = useCallback((buildingId: number) =>
		dispatch({
			type: "buildingChanged",
			payload: buildingId
		}), [dispatch])

	const onFloorChange = useCallback((floorId: number) =>
		dispatch({
			type: "floorChanged",
			payload: floorId
		}), [dispatch])

	const isLastItemInLastRowIn2ColumnLayout = useCallback(
		<T,>(index: number, array: readonly T[]) =>
			index % 2 === 0 && index === array.length - 1, [])

	const isLastItemInLastRow = useCallback(
		<T,>(index: number, array: readonly T[]) =>
			index % 4 === 0 && index === array.length - 1, [])

	const isLast2GridItemsInLastRow = useCallback(
		<T,>(index: number, array: readonly T[]) =>
			(index % 4 === 0 && index === (array.length - 1 ?? 0) - 1)
			|| (index % 4 === 1 && index === array.length - 1), [])

	const isLast3GridItemsInLastRow = useCallback(
		<T,>(index: number, array: readonly T[]) =>
			(index % 4 === 0 && index === (array.length - 1 ?? 0) - 2)
			|| (index % 4 === 1 && index === (array.length - 1 ?? 0) - 1)
			|| (index % 4 === 2 && index === array.length - 1), [])

	return <div className={style.structureGroups}>
		<section>
			<span>Standort:</span>
			{structure.length > 0
				? <div className={style.structures}>
					{structure.map(site =>
						<Button key={site.Id} size="lg" className={style.structure}
							variant={state.siteId !== site.Id ? "primary" : "secondary"}
							onClick={() => onSiteChange(site.Id)}>
							{site.Name}
						</Button>)}
				</div>
				: null}
		</section>
		{state.siteId !== defaultRoomSelectionState.siteId && !onlyOneBuildingInSite(state.siteId) &&
			<section>
				<span>Gebäude:</span>
				<div className={style.structures}>
					{structure.find(f => f.Id === state.siteId)
						?.Buildings.map(building =>
							<Button key={building.Id} size="lg" className={style.structure}
								variant={state.buildingId !== building.Id ? "primary" : "secondary"}
								onClick={() => onBuildingChange(building.Id)}>
								{building.Name}
							</Button>)}
				</div>
			</section>}
		{state.buildingId !== defaultRoomSelectionState.buildingId &&
			<section>
				<span>Stockwerk:</span>
				<div className={style.structures}>
					{structure.find(f => f.Id === state.siteId)
						?.Buildings.find(f => f.Id === state.buildingId)
						?.Floors.map(floor =>
							<Button key={floor.Id} size="lg" className={style.structure}
								variant={state.floorId !== floor.Id ? "primary" : "secondary"}
								onClick={() => onFloorChange(floor.Id)}>
								{floor.Name}
							</Button>)}
				</div>
			</section>
		}
		{state.floorId !== defaultRoomSelectionState.floorId &&
			<section>
				<span>Raum:</span>
				<div className={style.rooms}>
					{structure.find(f => f.Id === state.siteId)
						?.Buildings.find(f => f.Id === state.buildingId)
						?.Floors.find(f => f.Id === state.floorId)
						?.Rooms.map((room, index, rooms) =>
							<Button key={room.Id} size="lg" variant="primary"
								className={`${style.structure}
								${!isDesktop && twoColumnMobileRoomLayoutSiteIds.has(state.siteId)
										? isLastItemInLastRowIn2ColumnLayout(index, rooms)
											? style.fullWidthColumn
											: style.halfWidthColumn
										: isLastItemInLastRow(index, rooms)
											? style.fullWidthColumn
											: isLast2GridItemsInLastRow(index, rooms)
												? style.halfWidthColumn
												: isLast3GridItemsInLastRow(index, rooms)
													? style.thirdWidthColumn
													: style.quarterWidthColumn}`}
								onClick={() => onRoomSelected(room.Id)}>
								{room.Name}
							</Button>
						)}
				</div>
			</section>
		}
	</div>
}

export default RoomSelection
