import {
	FC,
	useEffect,
	useContext,
	useState
} from "react"
import { DataContext } from '../data/dataContext'
import { getRetiredEmployees } from '../data/dataAccess'
import { AuthenticationContext } from '../authentication/AuthenticationContext'
import EmployeeItems from '../data/models/EmployeeItems'
import EmployeeItemsList from './EmployeeItemsList'
import { CreateCategoryGroupedItemsShareText } from "../data/itemHelper"
import BottomRightCornerActions from './BottomRightCornerActions'
import Share from './Share'

const RetiredEmployees: FC = () => {

	const [retiredEmployees, setRetiredEmployees] = useState([] as readonly EmployeeItems[])

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		itemCategories,
		onRequestFailure,
		onRequestUnauthorized
	} = useContext(DataContext)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const response = await getRetiredEmployees({
					accountInfo,
					onRequestFailure,
					onRequestUnauthorized
				})

				if (response)
					setRetiredEmployees(response)
			}
		})()
	}, [accountInfo, onRequestFailure, onRequestUnauthorized])

	const onShare = () => {
		if (navigator.share) {
			navigator.share({
				text: `ausgetretene Mitarbeiter:\n\n
				${retiredEmployees.map(m =>
					`Mitarbeiter: ${m.Surname} ${m.Name}\n
					${CreateCategoryGroupedItemsShareText(itemCategories, m.Items)}`)
						.join("\n\n\n")}`
					.split("\n").map(m => m.trim()).join("\n")
			})
		}
	}

	return <>{retiredEmployees.length > 0
		&& <>
			<EmployeeItemsList employees={retiredEmployees} />
			<BottomRightCornerActions>
				<Share title="teile Artikelliste" onShare={onShare} />
			</BottomRightCornerActions>
		</>
	}</>
}

export default RetiredEmployees