import {
	FC,
	useCallback
} from "react"
import Button from "react-bootstrap/Button"
import { ScannerItemAction } from "../data/ScannerItemReducer"

interface Props {
	scannerItemDispatch: (action: ScannerItemAction) => void,
}

const ScannerRestart: FC<Props> = ({ scannerItemDispatch }) => {
	const onRescan = useCallback(() =>
		scannerItemDispatch({ type: "rescan" }),
		[scannerItemDispatch])

	return <Button size="lg" onClick={onRescan}>
		Artikel neu scannen
	</Button>
}

export default ScannerRestart
