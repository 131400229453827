import { useNavigate } from "react-router"
import {
	itemPath,
	employeePath,
	roomPath
} from "./routingPaths"

type NavigationHookInstance = ReturnType<typeof useNavigate>

export const navigateToItem = (navigation: NavigationHookInstance, itemId: number) => {
	navigation(`${itemPath}/${itemId}`)
}

export const navigateToEmployee = (navigation: NavigationHookInstance, employeeId: number) => {
	navigation(`${employeePath}/${employeeId}`)
}

export const navigateToRoom = (navigation: NavigationHookInstance, roomId: number) => {
	navigation(`${roomPath}/${roomId}`)
}
