import {
	FC,
	useCallback,
	useRef,
	useState,
	useEffect,
	FormEvent
} from "react"
import {
	useNavigate,
	useLocation,
} from "react-router-dom"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faSearch,
	faTimes
} from "@fortawesome/pro-solid-svg-icons"
import { searchResultsPath } from "../routes/routingPaths"
import style from "./style/Search.module.scss"

interface Props {
	className?: string
}

const Search: FC<Props> = ({ className }) => {
	const navigation = useNavigate()

	const location = useLocation()

	const searchInput = useRef(null as null | HTMLInputElement)

	const [showClearSearch, setShowClearSearch] = useState(null as null | boolean)

	const clearInput = useCallback(() => {
		if (searchInput.current) {
			searchInput.current.value = ""
			searchInput.current.dispatchEvent(new Event("input"))
		}
	}, [])

	useEffect(() => {
		if (searchInput.current) {
			searchInput.current.addEventListener("input", event =>
				setShowClearSearch((event.target as HTMLInputElement).value.length > 0)
			)
		}
	}, [])

	const onSubmit = useCallback((event: FormEvent<HTMLElement>) => {
		event.preventDefault()

		if (searchInput.current
			&& searchInput.current.value.length > 0
			&& searchInput.current.reportValidity()) {
			const searchResultsFullPath = `${searchResultsPath}/${searchInput.current.value}`
			clearInput()
			if (searchResultsFullPath === location.pathname)
				navigation(searchResultsFullPath, { replace: true })
			else
				navigation(searchResultsFullPath)
		}
	}, [location.pathname, clearInput, navigation])

	/* Examples:
	inventoryNumber: 170007000157
	device name: PC-CC
	serial number: CZC7248021
	imei: 35 639510 256853 6
	*/
	/* todo bootstrap v5/react-bootstrap v2
	https://getbootstrap.com/docs/5.0/migration/#forms
	delete inputgroup append, buttons are direct children of inputgroup*/
	return <Form onSubmit={onSubmit} className={className}>
		<InputGroup size="lg">
			<Form.Control as="input" ref={searchInput} className={style.search}
				type="search" inputMode="search" minLength={3}
				maxLength={200} title="Suchfeld" />
			{showClearSearch
				&& <Button className={style.clearSearch} onClick={clearInput} title="Leere Suchfeld">
					<FontAwesomeIcon icon={faTimes} />
				</Button>}
			<Button type="submit" title="Starte Suche">
				<FontAwesomeIcon icon={faSearch} />
			</Button>
		</InputGroup>
	</Form>
}

export default Search
