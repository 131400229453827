import { FC } from "react"
import { ScannerItemState } from "../data/ScannerItemReducer"
import ItemDetails from "./ItemDetails"

interface Props {
	scannerState: ScannerItemState,
}

const ScannedItem: FC<Props> = ({ scannerState: { item } }) => {
	return <>
		{item
			&& <ItemDetails item={item} />}
	</>
}

export default ScannedItem
