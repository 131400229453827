import {
	FC,
	Dispatch
} from "react"
import { useNavigate } from "react-router-dom"
import withAITracking from "../applicationInsights/applicationInsights"
import RoomSelection from "./RoomSelection"
import {
	RoomSelectionState,
	RoomSelectionAction
} from "../data/RoomSelectionReducer"
import { navigateToRoom } from "../routes/routingFunctions"

interface Props {
	state: RoomSelectionState
	dispatch: Dispatch<RoomSelectionAction>,
}

const ItemRoomSelection: FC<Props> = props => {
	const navigation = useNavigate()

	return <RoomSelection {...props}
		onRoomSelected={roomId => navigateToRoom(navigation, roomId)} />
}

export default withAITracking(ItemRoomSelection, "ItemRoomSelection")
