import {
	FC,
	useEffect,
	useState,
	useContext
} from "react"
import { useParams } from "react-router"
import ItemDetails from "./ItemDetails"
import { getItem } from "../data/dataAccess"
import withAITracking from "../applicationInsights/applicationInsights"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { itemIdParameter } from "../routes/routingPaths"
import { DataContext } from "../data/dataContext"
import ReadOnlyItem from "../appmodels/ReadOnlyItem"

const Item: FC = () => {
	const id = Number((useParams())[itemIdParameter])

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure
	} = useContext(DataContext)

	const [item, setItem] = useState(null as null | ReadOnlyItem)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const response = await getItem({
					onRequestUnauthorized,
					onRequestFailure,
					accountInfo
				}, {
					type: "id",
					id
				})
				if (response)
					setItem(response)
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, accountInfo, id])

	return <>
		{item && <ItemDetails item={item} />}
	</>
}

export default withAITracking(Item, "Item")
