import {
	FC,
	ReactNode,
	useCallback
} from "react"
import Button, { ButtonProps } from "react-bootstrap/Button"
import Room from "../data/models/Room"

interface Props {
	room: Room
	onClick: (roomId: number) => void
	customRoomName?: (room: Room) => ReactNode
	id?: string
	buttonProps?: ButtonProps,
}

const RoomRecord: FC<Props> = ({
	room,
	onClick,
	customRoomName,
	buttonProps }) => {
	const onRoomRecordClick = useCallback(() => onClick(room.Id), [onClick, room.Id])

	return <Button size="lg" onClick={onRoomRecordClick} {...buttonProps}>
		{room.Site} {customRoomName ? customRoomName(room) : room.Name.replace("/", " ")}
	</Button>
}

export default RoomRecord
