import {
	FC,
	useEffect,
	useContext,
	useReducer,
	useCallback,
	ChangeEvent,
	useMemo
} from "react"
import {
	useParams,
	useNavigate
} from "react-router-dom"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faTimes,
	faSave
} from "@fortawesome/pro-solid-svg-icons"
import moment from "moment-timezone"
import {
	itemIdParameter,
	itemPath
} from "../routes/routingPaths"
import {
	getRoom,
	getEditItem,
	updateItem,
	getEmployee,
	ScannedItemRequestType
} from "../data/dataAccess"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import withAITracking from "../applicationInsights/applicationInsights"
import BottomRightCornerActions from "./BottomRightCornerActions"
import EmployeeRecord from "./EmployeeRecord"
import RoomRecord from "./RoomRecord"
import RoomSelection from "./RoomSelection"
import Room from "../data/models/Room"
import Employee from "../data/models/Employee"
import EmployeeSelection from "./EmployeeSelection"
import { DataContext } from "../data/dataContext"
import EditItem from "../data/models/EditItem"
import {
	defaultRoomSelectionState,
	RoomSelectionReducer
} from "../data/RoomSelectionReducer"
import ModifiedItem from "../data/models/ModifiedItem"
import { europeVienna } from "../data/dataHelper"
import {
	storageStateId,
	itemWithDeviceNameCategoryIds,
	noCategoryId,
	toBeRemovedStateId,
	rmaStateId,
	rmaRoomId,
	takenOverByHRStateId
} from "../data/itemHelper"
import style from "./style/ItemEditor.module.css"

const needsStorageRoomStateIds: ReadonlySet<number> = new Set([storageStateId, toBeRemovedStateId])

const needsEmployeeStateIds: ReadonlySet<number> = new Set([4])

const needsRoomCategoryIds: ReadonlySet<number> = new Set([2, 3, 6, 12])

const needsRoomOrEmployeeCategoryIds: ReadonlySet<number> = new Set([1, 5, 7, 8])

const needsEmployeeCategoryIds: ReadonlySet<number> = new Set([4, 11, 15])

const unselectableStateIds: ReadonlySet<number> = new Set([takenOverByHRStateId])

type Action =
	| { type: "inital", payload: EditItem }
	| { type: "setName", payload: string }
	| { type: "setState", payload: { stateId: number, rmaRoom: Room | null } }
	| { type: "setCategory", payload: number }
	| { type: "setDeviceName", payload: string }
	| { type: "setComment", payload: string }
	| { type: "deleteRoom" }
	| { type: "selectingRoom" }
	| { type: "cancelRoomSelection" }
	| { type: "setRoom", payload: Room }
	| { type: "deleteEmployee" }
	| { type: "selectingEmployee" }
	| { type: "cancelEmployeeSelection" }
	| { type: "setEmployee", payload: Employee }
	| { type: "setLampHours", payload: number }

interface State {
	item: EditItem
	isSelectingRoom: boolean
	isSelectingEmployee: boolean,
}

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "inital":
			return {
				...state,
				item: action.payload
			}
		case "setName":
			return {
				...state,
				item: {
					...state.item,
					Name: action.payload
				}
			}
		case "setDeviceName":
			return {
				...state,
				item: {
					...state.item,
					DeviceName: action.payload
				}
			}
		case "setState":
			return {
				...state,
				isSelectingRoom: action.payload.rmaRoom === null
					&& (needsStorageRoomStateIds.has(action.payload.stateId)
						|| state.isSelectingRoom),
				item: {
					...state.item,
					StateId: action.payload.stateId,
					Room: needsEmployeeStateIds.has(action.payload.stateId)
						? null
						: action.payload.rmaRoom !== null
							? action.payload.rmaRoom
							: state.item.Room,
					Employee: action.payload.stateId === rmaStateId
						? null
						: state.item.Employee
				}
			}
		case "setCategory":
			return {
				...state,
				item: {
					...state.item,
					CategoryId: action.payload
				}
			}
		case "setComment":
			return {
				...state,
				item: {
					...state.item,
					Comment: action.payload
				}
			}
		case "deleteRoom":
			return {
				...state,
				item: {
					...state.item,
					Room: null
				}
			}
		case "selectingRoom":
			return {
				...state,
				isSelectingRoom: true
			}
		case "cancelRoomSelection":
			return {
				...state,
				isSelectingRoom: false
			}
		case "setRoom":
			return {
				...state,
				isSelectingRoom: false,
				item: {
					...state.item,
					Room: action.payload,
					Employee: null,
				}
			}
		case "deleteEmployee":
			return {
				...state,
				item: {
					...state.item,
					Employee: null,
					Room: state.item.Employee && state.item.Employee.Room
				}
			}
		case "selectingEmployee":
			return {
				...state,
				isSelectingEmployee: true
			}
		case "cancelEmployeeSelection":
			return {
				...state,
				isSelectingEmployee: false
			}
		case "setEmployee":
			return {
				...state,
				isSelectingEmployee: false,
				item: {
					...state.item,
					Employee: action.payload,
					Room: action.payload.Room,
					IsRoomResource: false
				}
			}
		case "setLampHours":
			return {
				...state,
				item: {
					...state.item,
					LampHours: action.payload
				}
			}
	}
}

interface Props {
	itemRequestType?: ScannedItemRequestType,
}

const ItemEditor: FC<Props> = ({ itemRequestType }) => {
	const routeItemId = (useParams())[itemIdParameter]

	const id = routeItemId ? Number(routeItemId) : null

	const navigation = useNavigate()

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure,
		userEmployeeId,
		itemCategories,
		itemStates,
		storageRooms
	} = useContext(DataContext)

	const emptyItem: EditItem = useMemo(() => ({
		Id: 0,
		StateId: itemStates[0]?.Id ?? 0,
		CategoryId: itemCategories[0]?.Id ?? 0,
		Name: "",
		InventoryNumber: 0,
		SerialNumber: "",
		Comment: "",
		DeviceName: "",
		IMEI: "",
		Room: null,
		IsRoomResource: false,
		Employee: null,
		LampHours: 0,
		ItemHistory: []
	}), [itemStates, itemCategories])

	const [state, dispatch] = useReducer(reducer, {
		item: emptyItem,
		isSelectingEmployee: false,
		isSelectingRoom: false
	})

	const [roomSelectionState, roomSelectionDispatch] = useReducer(RoomSelectionReducer, defaultRoomSelectionState)

	useEffect(() => {
		(async () => {
			if (accountInfo && id !== null) {
				const item = await getEditItem({
					onRequestUnauthorized,
					onRequestFailure,
					accountInfo
				}, id)

				if (item) {
					dispatch({
						type: "inital",
						payload: item
					})
				}
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, accountInfo, id])

	const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.value
		dispatch({ type: "setName", payload: name })
	}, [])

	const onStateChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
		const stateId = parseFloat(event.target.value)

		const rmaRoom = stateId === rmaStateId
			? storageRooms.find(f => f.Id === rmaRoomId) ?? null
			: null

		dispatch({
			type: "setState",
			payload: {
				stateId,
				rmaRoom
			}
		})
	}, [storageRooms])

	const onCategoryChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
		const categoryId = parseFloat(event.target.value)
		dispatch({ type: "setCategory", payload: categoryId })
	}, [])

	const onDeviceNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const deviceName = event.target.value
		dispatch({ type: "setDeviceName", payload: deviceName })
	}, [])

	const onLampHoursChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const lampHours = event.target.value
		dispatch({ type: "setLampHours", payload: parseFloat(lampHours) })
	}, [])

	const onDeleteRoom = useCallback(() => dispatch({ type: "deleteRoom" }), [])

	const onSelectRoom = useCallback(() => dispatch({ type: "selectingRoom" }), [])

	const onCancelRoomSelecting = useCallback(() => {
		dispatch({ type: "cancelRoomSelection" })
		roomSelectionDispatch({ type: "reset" })
	}, [])

	const onRoomSelected = useCallback(async (roomId: number) => {
		if (accountInfo) {
			const room = await getRoom({
				onRequestUnauthorized,
				onRequestFailure,
				accountInfo
			}, roomId)

			if (room)
				dispatch({
					type: "setRoom",
					payload: room
				})
		}
	}, [onRequestUnauthorized, onRequestFailure, accountInfo])

	const onDeleteEmployee = useCallback(() => dispatch({ type: "deleteEmployee" }), [])

	const onSelectEmployee = useCallback(() => dispatch({ type: "selectingEmployee" }), [])

	const onCancelEmployeeSelecting = useCallback(() => dispatch({ type: "cancelEmployeeSelection" }), [])

	const onEmployeeSelected = useCallback(async (employeeId: number) => {
		if (accountInfo) {
			const employee = await getEmployee({
				onRequestUnauthorized,
				onRequestFailure,
				accountInfo
			}, employeeId)

			if (employee)
				dispatch({
					type: "setEmployee",
					payload: employee
				})
		}
	}, [onRequestUnauthorized, onRequestFailure, accountInfo])

	const onCommentChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		const comment = event.target.value
		dispatch({ type: "setComment", payload: comment })
	}, [])

	const onCancel = useCallback(() =>
		navigation(`${itemPath}/${state.item.Id}`),
		[navigation, state.item.Id])

	const needsStorageRoom = needsStorageRoomStateIds.has(state.item.StateId)

	const isCategorySelected = state.item.CategoryId !== noCategoryId

	const isRoomOrEmployeeSelected =
		(needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId) && [state.item.Employee, state.item.Room].some(s => s))
		|| (needsRoomCategoryIds.has(state.item.CategoryId) && state.item.Room !== null)
		|| (needsEmployeeCategoryIds.has(state.item.CategoryId) && state.item.Employee !== null)
		|| (needsStorageRoom && state.item.Room !== null)

	const isFormValid = isRoomOrEmployeeSelected
		&& isCategorySelected

	const currentRoom = state.item.StateId === rmaStateId
		? state.item.Room
		: state.item.Employee?.Room ?? state.item.Room

	const onSubmit = useCallback(async () => {
		if (isFormValid && accountInfo) {
			const modifiedItem: ModifiedItem = {
				Name: state.item.Name,
				StateId: state.item.StateId,
				CategoryId: state.item.CategoryId,
				EmployeeId: !needsStorageRoomStateIds.has(state.item.StateId)
					&& state.item.Employee !== null
					&& (needsEmployeeCategoryIds.has(state.item.CategoryId)
						|| needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId))
					? state.item.Employee.Id
					: null,
				RoomId: state.item.Room !== null
					&& (needsStorageRoom
						|| (needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId) && state.item.Employee === null)
						|| needsRoomCategoryIds.has(state.item.CategoryId)
						|| state.item.StateId === rmaStateId)
					? state.item.Room.Id
					: null,
				SerialNumber: state.item.SerialNumber,
				DeviceName: state.item.DeviceName,
				IMEI: state.item.IMEI,
				LampHours: state.item.LampHours,
				Comment: state.item.Comment,
				UserId: userEmployeeId
			}

			await updateItem({
				onRequestUnauthorized,
				onRequestFailure,
				accountInfo
			}, {
				...modifiedItem,
				Id: state.item.Id
			})

			navigation(`${itemPath}/${state.item.Id}`)
		}

	}, [onRequestUnauthorized, onRequestFailure, isFormValid, accountInfo, userEmployeeId, needsStorageRoom,
		state.item.Id, state.item.Name, state.item.StateId, state.item.CategoryId, state.item.Employee,
		state.item.Room, state.item.SerialNumber, state.item.IMEI, state.item.LampHours,
		state.item.DeviceName, state.item.Comment, navigation])

	const nameLabel = "name"
	const categoryIdLabel = "categoryId"
	const stateIdLabel = "stateId"
	const employeeLabel = "employee"
	const roomLabel = "room"
	const commentLabel = "comment"
	const deviceNameLabel = "deviceName"
	const lampHoursLabel = "lampHours"

	return <>{(itemRequestType || state.item.Id > 0) &&
		<form className={style.form} onSubmit={onSubmit}>
			{!isRoomOrEmployeeSelected
				? <p className={style.errorDescription}>
					Ein Artikel muss {needsStorageRoom ? <>einem Lager</> : <>mind. einem Mitarbeiter oder einem Raum</>} zugewiesen sein.
				</p>
				: null}
			{!isCategorySelected
				? <p className={style.errorDescription}>
					Ein Artikel muss einer Kategorie zugeordnet sein.
				</p>
				: null}
			<h3>
				{state.item.InventoryNumber}
			</h3>
			<div>
				<label htmlFor={nameLabel} className={style.textboxContainer}>
					<span className={style.formLabel}>
						Bezeichnung:
					</span>
				</label>
				<input type="text" id={nameLabel} className={style.textbox} value={state.item.Name} required={true}
					maxLength={500} onChange={onNameChange} />
			</div>
			<div>
				<div className={style.twoColumnLayout}>
					<label htmlFor={categoryIdLabel}>
						<span className={style.formLabel}>
							Kategorie:
						</span>
					</label>
					<label htmlFor={stateIdLabel}>
						<span className={style.formLabel}>
							Status:
						</span>
					</label>
				</div>
				<div className={style.twoColumnLayout}>
					<select value={state.item.CategoryId} onChange={onCategoryChange} id={categoryIdLabel}>
						{itemCategories
							.filter(f => f.Id !== noCategoryId || state.item.CategoryId === noCategoryId)
							.map(m =>
								<option key={m.Id} value={m.Id}>
									{m.Name}
								</option>)}
					</select>
					<select value={state.item.StateId} onChange={onStateChange} id={stateIdLabel}>
						{itemStates
							.filter(f => !unselectableStateIds.has(f.Id))
							.map(m => <option key={m.Id} value={m.Id}>{m.Name}</option>)}
					</select>
				</div>
			</div>
			{!needsStorageRoom
				&& (needsEmployeeStateIds.has(state.item.StateId)
					|| needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId)
					|| needsEmployeeCategoryIds.has(state.item.CategoryId))
				&& state.item.StateId !== rmaStateId
				&& <fieldset>
					<label htmlFor={employeeLabel} className={style.formLabel}>
						Mitarbeiter:
					</label>
					{state.isSelectingEmployee
						? <div className={style.singleColumnLayout}>
							<EmployeeSelection onEmployeeSelect={onEmployeeSelected} />
							<div className={style.twoColumnLayout}>
								<Button size="lg" onClick={onCancelEmployeeSelecting}>
									Abbrechen
								</Button>
							</div>
						</div>
						: state.item.Employee
							? <div className={style.singleColumnLayout}>
								<EmployeeRecord
									employee={state.item.Employee}
									onClick={onSelectEmployee}
									id={employeeLabel} />
								<div className={style.twoColumnLayout}>
									<Button
										size="lg"
										variant="secondary"
										onClick={onDeleteEmployee}>
										Entfernen
									</Button>
								</div>
							</div>
							: <div className={style.twoColumnLayout}>
								<Button disabled={
									needsRoomCategoryIds.has(state.item.CategoryId)
									|| (needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId)
										&& state.item.Room !== null)}
									size="lg"
									onClick={onSelectEmployee}>
									Auswählen
								</Button>
							</div>
					}
				</fieldset>}
			{!needsEmployeeStateIds.has(state.item.StateId)
				&& (needsRoomCategoryIds.has(state.item.CategoryId)
					|| needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId)
					|| needsStorageRoom
					|| state.item.StateId === rmaStateId)
				&& <fieldset>
					<div className={style.twoColumnLayout}>
						<label htmlFor={roomLabel} className={`${style.formLabel} ${style.fullWidthGridItem}`}>
							Raum:
						</label>
						{state.isSelectingRoom
							? <>{needsStorageRoom
								? <div className={`${style.storageRooms} ${style.fullWidthGridItem}`}>
									{storageRooms.map((room) =>
										<Button key={room.Id}
											size="lg"
											className={style.storageRoom}
											onClick={() =>
												dispatch({
													type: "setRoom",
													payload: room
												})
											}>
											{room.Name.split(" ").join("\n")}
										</Button>
									)}
								</div>
								: <div className={`${style.singleColumnLayout} ${style.fullWidthGridItem}`}>
									<RoomSelection state={roomSelectionState} dispatch={roomSelectionDispatch}
										onRoomSelected={onRoomSelected} />
									<div className={style.twoColumnLayout}>
										<Button size="lg" onClick={onCancelRoomSelecting}>
											Abbrechen
										</Button>
									</div>
								</div>
							}</>
							: currentRoom !== null
								? <>
									<RoomRecord
										room={currentRoom}
										onClick={onSelectRoom}
										buttonProps={{
											id: roomLabel,
											disabled: state.item.StateId === rmaStateId
												|| needsEmployeeCategoryIds.has(state.item.CategoryId)
												|| (needsRoomOrEmployeeCategoryIds.has(state.item.CategoryId) && state.item.Employee !== null),
										}} />
									{state.item.StateId !== rmaStateId && !state.item.Employee
										&& <Button size="lg" variant="secondary" onClick={onDeleteRoom}>
											Entfernen
										</Button>}
								</>
								: <Button size="lg" onClick={onSelectRoom}>
									Raum auswählen
								</Button>
						}
					</div>
				</fieldset>}
			{itemWithDeviceNameCategoryIds.has(state.item.CategoryId)
				&& <div>
					<label htmlFor={deviceNameLabel} className={style.formLabel}>
						Gerätebezeichnung:
					</label>
					<input type="text" id={deviceNameLabel} className={style.textbox}
						value={state.item.DeviceName === null ? "" : state.item.DeviceName}
						maxLength={500} onChange={onDeviceNameChange} />
				</div>}
			{state.item.CategoryId === 3
				&& <div>
					<label htmlFor={lampHoursLabel}>Lampenstunden</label>
					<input type="number" id={lampHoursLabel} className={style.textbox}
						value={state.item.LampHours ?? 0} inputMode="numeric"
						min={0} onChange={onLampHoursChange} />
				</div>}
			<div>
				<label htmlFor={commentLabel} className={style.formLabel}>
					Artikelkommentar:
				</label>
				<textarea className={style.freeFormText} rows={3} id={commentLabel}
					value={state.item.Comment === null ? "" : state.item.Comment}
					onChange={onCommentChange} maxLength={1000}></textarea>
			</div>
			<div>
				<label className={style.formLabel}>
					Seriennummer:
				</label>
				<div>
					{state.item.SerialNumber}
				</div>
			</div>
			<div>
				<label className={style.formLabel}>
					Verlauf:
				</label>
				<div className={style.history}>
					{state.item.ItemHistory.map((m, i) =>
						<div key={i}>
							<div>
								{moment.tz(m.DateTime, europeVienna).format("DD.MM.YYYY")} {m.User.Surname} {m.User.Name}
							</div>
							<div>
								{m.Changes}
							</div>
						</div>)}
				</div>
			</div>
			<BottomRightCornerActions>
				<Button variant="secondary" onClick={onSubmit} disabled={!isFormValid}>
					<FontAwesomeIcon icon={faSave} size="2x" fixedWidth={true} title="Änderungen speichern" />
				</Button>
				<Button variant="secondary" onClick={onCancel}>
					<FontAwesomeIcon icon={faTimes} size="2x" fixedWidth={true} title="Abbrechen" />
				</Button>
			</BottomRightCornerActions>
		</form>
	}</>
}

export default withAITracking(ItemEditor, "ItemEditor")
