import {
	FC,
	Dispatch,
	useContext
} from "react"
import {
	ScannerItemState,
	onScannerScan,
	ScannerItemAction
} from "../data/ScannerItemReducer"
import {
	useLocation,
	useNavigate
} from "react-router-dom"
import { scannerItemPath } from "../routes/routingPaths"
import ScannerRestart from "./ScannerRestart"
import Scanner from "./Scanner"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { DataContext } from "../data/dataContext"
import style from "./style/ScannerItemDesktop.module.css"

interface Props {
	state: ScannerItemState
	dispatch: Dispatch<ScannerItemAction>,
}

const ScannerItemDesktop: FC<Props> = ({ state, dispatch }) => {
	const navigation = useNavigate()

	const location = useLocation()

	const { accountInfo } = useContext(AuthenticationContext)

	const { onRequestFailure,
		onRequestUnauthorized } = useContext(DataContext)

	return <div className={style.desktopScanner}>
		<div>
			{location.pathname === scannerItemPath
				&& state.renderScanner
				&& <Scanner onScan={async (itemRequestType) =>
					await onScannerScan(itemRequestType, accountInfo,
						{
							onRequestFailure,
							onRequestUnauthorized
						}, dispatch)} />}
		</div>
		<ScannerRestart scannerItemDispatch={
			action => {
				dispatch(action)
				navigation(scannerItemPath)
			}} />
	</div>
}

export default ScannerItemDesktop
