import {
	FC,
	useCallback,
	CSSProperties
} from "react"
import Button from "react-bootstrap/Button"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ItemIcon from "./ItemIcon"
import { isDesktop } from "../data/styleHelper"
import style from "./style/ItemRecordComponent.module.css"
import ItemRecord from "../data/models/ItemRecord"

interface Props {
	item: ItemRecord
	onClick: (itemId: number) => void,
}

const ItemRecordComponent: FC<Props> = ({
	item,
	onClick,
	children
}) => {

	const ellipsisOverflow: CSSProperties =
	{
		maxWidth: isDesktop
			? `calc((100vw - 3rem) / 2 - 7rem)`
			: `calc(100vw - 9rem)`
	}

	const onItemClick = useCallback(() =>
		onClick(item.Id), [item, onClick])

	return <Button size="lg" onClick={onItemClick}>
		<span className={style.item}>
			<ItemIcon categoryId={item.CategoryId} />
			<span className={style.itemData}>
				<span className={style.ellipsisOverflow} style={ellipsisOverflow}>
					{item.Name}
				</span>
				<span>
					Status: {item.State}
				</span>
				<span>
					{children || item.InventoryNumber || ""}
				</span>
			</span>
			<span className={style.toItem}>
				<FontAwesomeIcon icon={faChevronRight} size="1x" title="Zum Artikel" />
			</span>
		</span>
	</Button>
}

export default ItemRecordComponent
