import { ComponentType } from "react"
import { createHashHistory } from "history"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import {
	withAITracking,
	ReactPlugin
} from "@microsoft/applicationinsights-react-js"

export const history = createHashHistory()

const reactPlugin = new ReactPlugin()

export const applicationinsights = new ApplicationInsights({
	config: {
		instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: {
				history
			}
		},
		disableFetchTracking: false
	}
})

if (process.env.NODE_ENV === "production")
	applicationinsights.loadAppInsights()

const withAiTracking = <P>(component: ComponentType<P>, displayName: string) =>
	process.env.NODE_ENV === "production"
		? withAITracking(reactPlugin, component, displayName)
		: component
export default withAiTracking