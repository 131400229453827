import {
	FC,
	Dispatch
} from "react"
import {
	scannerItemPath,
	roomsPath,
	employeesPath,
	beamerItems,
} from "../routes/routingPaths"
import {
	RoomSelectionAction,
	initialRoomSelectionState
} from "../data/RoomSelectionReducer"
import { LinkContainer } from "react-router-bootstrap"
import Button from "react-bootstrap/Button"
import ItemsWithSameStateLinksFactory, { rma, notFound, auszuscheiden, takenOverByHR } from "./ItemsWithSameStateLinks"
import RetiredEmployeesLink from "./RetiredEmployeesLink"
import style from "./style/NavigationMobile.module.scss"
import { beamerNavigationText } from "../data/itemHelper"
import { isDesktop } from "../data/styleHelper"

interface Props {
	roomSelectionDispatch: Dispatch<RoomSelectionAction>,
}

const NavigationMobile: FC<Props> = ({ roomSelectionDispatch }) => {
	return <nav className={style.navigationMobile}>
		{[{
			name: "Scanner",
			path: scannerItemPath,
		},
		{
			name: "Mitarbeiter",
			path: employeesPath,
		},
		{
			name: "Raum",
			path: roomsPath,
			onClick: () =>
				roomSelectionDispatch({
					type: "siteChanged",
					payload: initialRoomSelectionState,
				})
		}].map(m =>
			<LinkContainer key={m.path} to={m.path}
				{...(m.onClick ? { onClick: m.onClick } : {})}>
				<Button
					size={isDesktop ? "lg" : undefined}
					className={style.navigationLink}>
					<span className={style.navigationLinkText}>
						{m.name}
					</span>
				</Button>
			</LinkContainer>
		)}
		<div className={style.itemsWithSameStateLinks}>
			<ItemsWithSameStateLinksFactory
				link={rma} />
			<LinkContainer to={beamerItems}>
				<Button
					size={isDesktop ? "lg" : undefined}>
					{beamerNavigationText}
				</Button>
			</LinkContainer>
			{[notFound, auszuscheiden, takenOverByHR].map(m =>
				<ItemsWithSameStateLinksFactory
					key={m.path}
					link={m} />)}
			<RetiredEmployeesLink />
		</div>
	</nav>
}

export default NavigationMobile
