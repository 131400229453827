import { createContext } from "react"
import Employee from "./models/Employee"
import Site from "./models/Site"
import Category from "./models/Category"
import ItemState from "./models/ItemState"
import Room from "./models/Room"

interface Context {
	onRequestUnauthorized: () => void
	onRequestFailure: () => void
	userEmployeeId: number
	employees: readonly Employee[],
	structure: readonly Site[],
	storageRooms: readonly Room[],
	itemCategories: readonly Category[],
	itemStates: readonly ItemState[],
}

export const DataContext = createContext<Context>({
	onRequestUnauthorized: () => { },
	onRequestFailure: () => { },
	userEmployeeId: 0,
	employees: [],
	structure: [],
	storageRooms: [],
	itemCategories: [],
	itemStates: [],
})