import React, {
	FC,
	useContext,
	useEffect,
	useState
} from "react"
import { useNavigate } from "react-router"
import withAiTracking from "../applicationInsights/applicationInsights"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { getBeamerItems } from "../data/dataAccess"
import { DataContext } from "../data/dataContext"
import BeamerItems from "../data/models/BeamerItems"
import ItemRecord from "../data/models/ItemRecord"
import RoomItems from "../data/models/RoomItems"
import { groupBy } from "../data/utilityHelper"
import { navigateToItem } from "../routes/routingFunctions"
import BottomRightCornerActions from "./BottomRightCornerActions"
import ItemRecordComponent from "./ItemRecordComponent"
import SplittedList from "./SplittedList"
import style from "./style/BeamerItems.module.css"

const BeamerItemsComponent: FC = () => {

	const [state, setState] = useState(null as BeamerItems | null)

	const { accountInfo } = useContext(AuthenticationContext)

	const navigation = useNavigate()

	const {
		onRequestFailure,
		onRequestUnauthorized,
	} = useContext(DataContext)

	useEffect(() => {
		(async () => {
			if (accountInfo) {
				const response = await getBeamerItems({ accountInfo, onRequestFailure, onRequestUnauthorized })

				if (response) {
					setState(response)
				}
			}
		})()
	}, [accountInfo, onRequestFailure, onRequestUnauthorized])

	const beamerLists = state
		? [
			{ title: "Aktive Beamer", beamers: groupBy(room => room.Room?.Site, state.BeamerRoomItems) },
			{ title: "Lager", beamers: groupBy(room => room.Room?.Site, state.BeamerStorageRoomItems) }]
		: null

	return beamerLists
		? <div className={style.beamerLists}>
			{beamerLists.map(m => m.beamers.size > 0
				? <div key={m.title}>
					<h2>{m.title}</h2>
					{[...m.beamers.entries()].map(siteBeamers =>
						<React.Fragment key={siteBeamers[0]}>
							<h3>
								{siteBeamers[0]}
							</h3>
							<SplittedList<RoomItems>
								list={siteBeamers[1]}
								getKey={k => k.Room?.Id ?? 0}
								itemRender={room => <>
									<SplittedList<ItemRecord>
										list={room.Items}
										getKey={k => k.Id}
										itemRender={item => <ItemRecordComponent
											item={item}
											key={item.Id}
											onClick={itemId => navigateToItem(navigation, itemId)}>
											<span className={style.beamerDetails}>
												<span>
													{room.Room
														? room.Room.Name
														: null}
												</span>
												<span>
													Lampenstunden: {item.LampHours}
												</span>
											</span>
										</ItemRecordComponent>} />
								</>} />
						</React.Fragment>
					)}
				</div>
				: null)}
			<BottomRightCornerActions />
		</div>
		: null
}

export default withAiTracking(BeamerItemsComponent, "BeamerItems")
