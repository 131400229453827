import { createContext } from "react"

interface Context {
	missingPhotoEmployeeIds: Set<number>
	setMissingPhotoEmployeeIds: (employeeIds: Set<number>) => void
}

export const MissingPhotoEmployeeIds = createContext<Context>({
	missingPhotoEmployeeIds: new Set<number>(),
	setMissingPhotoEmployeeIds: () => { }
})