import {
	FC,
	useCallback,
	CSSProperties
} from "react"
import Button from "react-bootstrap/Button"
import SearchResultValue from "./SearchResultValue"
import ItemIcon from "./ItemIcon"
import SearchResultItem from "../data/models/SearchResultItem"
import { isDesktop } from "../data/styleHelper"
import style from "./style/SearchResultItemComponent.module.css"

interface Props {
	item: SearchResultItem
	itemProperty: string | number
	searchTerm: string
	onClick: (id: number) => void,
}

const SearchResultItemComponent: FC<Props> = ({
	item,
	onClick,
	itemProperty,
	searchTerm }) => {

	const ellipsisOverflow: CSSProperties = {
		maxWidth: isDesktop
			? `calc((100vw - 3rem) / 2 - 5rem)`
			: `calc(100vw - 6rem)`
	}

	const onItemClick = useCallback(() =>
		onClick(item.Id), [onClick, item.Id])

	return <Button size="lg" onClick={onItemClick}>
		<span className={style.searchResultItemLayout}>
			<ItemIcon categoryId={item.CategoryId} />
			<span className={style.searchResultItem}>
				<span className={style.ellipsisOverflow} style={ellipsisOverflow}>
					{item.Name}
				</span>
				<span>
					<SearchResultValue value={itemProperty.toString()} searchTerm={searchTerm} />
				</span>
				<span>
					Status: {item.State}
				</span>
			</span>
		</span>
	</Button>
}

export default SearchResultItemComponent
