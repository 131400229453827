import {
	FC,
	useContext,
	useMemo
} from "react"
import Button from "react-bootstrap/Button"
import style from "./style/EmployeeSurnameFilter.module.css"
import { DataContext } from "../data/dataContext"

interface Props {
	selectedEmployeeSurnameFirstLetter: string
	onFilter: (letter: string) => void,
}

const upperCaseAlphabet = [...Array(26).keys()].map(m => String.fromCharCode(m + 65))

const EmployeeSurnameFilter: FC<Props> = ({
	selectedEmployeeSurnameFirstLetter,
	onFilter }) => {
	const { employees } = useContext(DataContext)

	const employeeSurnameFirstLetters = useMemo(() =>
		new Set(employees.map(m => m.Surname.charAt(0).toUpperCase())),
		[employees])

	return <div className={style.employeeSurnameFirstLetterGroups}>
		{upperCaseAlphabet.map(m =>
			<Button key={m} size="lg" variant={m === selectedEmployeeSurnameFirstLetter ? "secondary" : "primary"}
				disabled={!employeeSurnameFirstLetters.has(m)}
				onClick={() => onFilter(m.charAt(0))}>
				{m}
			</Button>)}
	</div>
}

export default EmployeeSurnameFilter
