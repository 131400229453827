import { itemsByStatePath } from "../routes/routingPaths"
import Button from "react-bootstrap/Button"
import { LinkContainer } from "react-router-bootstrap"
import { isDesktop } from "../data/styleHelper"
import { FC } from "react"
import { NavLinkProps } from "react-bootstrap"

export const rma = {
	name: "RMA",
	path: `${itemsByStatePath}/${2}`,
}

export const notFound = {
	name: "Not Found",
	path: `${itemsByStatePath}/${6}`,
}

export const auszuscheiden = {
	name: "Auszuscheiden",
	path: `${itemsByStatePath}/${7}`,
}

export const takenOverByHR = {
	name: "Übernahme HR",
	path: `${itemsByStatePath}/${8}`,
}

interface Props extends NavLinkProps {
	link: {
		name: string
		path: string
	}
}

const ItemsWithSameStateLinksFactory: FC<Props> = ({ link, ...props }) =>
	<LinkContainer to={link.path} {...props}>
		<Button
			size={isDesktop ? "lg" : undefined}>
			{link.name}
		</Button>
	</LinkContainer>

export default ItemsWithSameStateLinksFactory
