import {
	FC,
	useState,
	useEffect,
	useContext,
	useCallback,
} from "react"
import {
	useParams,
	useNavigate
} from "react-router"
import { employeeIdParameter } from "../routes/routingPaths"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-solid-svg-icons"
import Button from "react-bootstrap/Button"
import withAITracking from "../applicationInsights/applicationInsights"
import { AuthenticationContext } from "../authentication/AuthenticationContext"
import { getEmployee } from "../data/dataAccess"
import { DataContext } from "../data/dataContext"
import RoomRecord from "./RoomRecord"
import EmployeePhoto from "./EmployeePhoto"
import Share from "./Share"
import { CreateCategoryGroupedItemsShareText } from "../data/itemHelper"
import SplittedList from "./SplittedList"
import ItemRecordComponent from "./ItemRecordComponent"
import BottomRightCornerActions from "./BottomRightCornerActions"
import EmployeeItems from "../data/models/EmployeeItems"
import { navigateToItem, navigateToRoom } from "../routes/routingFunctions"
import style from "./style/EmployeeDetails.module.css"
import ItemRecord from '../data/models/ItemRecord'

const EmployeeDetails: FC = () => {
	const id = Number((useParams() as any)[employeeIdParameter])

	const navigation = useNavigate()

	const { accountInfo } = useContext(AuthenticationContext)

	const {
		onRequestUnauthorized,
		onRequestFailure,
		itemCategories
	} = useContext(DataContext)

	const [employee, setEmployee] = useState({
		Id: 0,
		Surname: "",
		Name: "",
		Department: "",
		Username: "",
		Email: "",
		Items: [],
		Room: null,
		EntryDate: ""
	} as EmployeeItems)

	useEffect(() => {
		(async () => {
			if (id && accountInfo) {
				const response = await getEmployee({
					onRequestUnauthorized,
					onRequestFailure,
					accountInfo
				}, id)
				if (response)
					setEmployee(response)
			}
		})()
	}, [onRequestUnauthorized, onRequestFailure, id, accountInfo])

	const onShare = useCallback(() => {
		(async () => {
			if (navigator.share) {
				const text = `Mitarbeiter: ${employee.Surname} ${employee.Name}
				${employee.Room?.Site} ${employee.Room?.Name}\n
				${CreateCategoryGroupedItemsShareText(itemCategories, employee.Items)}`
					.split("\n").map(m => m.trim()).join("\n")

				await navigator.share({
					url: `${window.location.href}`,
					text
				})
			}
		})()
	}, [employee.Surname, employee.Name, employee.Room, itemCategories, employee.Items])

	return <>
		<h1>
			{employee.Surname} {employee.Name}
		</h1>
		<h3>
			{employee.Department}
		</h3>
		<p>
			{employee.EntryDate}
		</p>
		<div className={style.employeeDataLayout}>
			{employee.Id !== 0
				&& <EmployeePhoto employee={employee} />}
			{employee.Room
				&& <RoomRecord room={employee.Room} onClick={roomId => navigateToRoom(navigation, roomId)} />}
			{employee.Email
				? <Button>
					<a href={`https://teams.microsoft.com/l/chat/0/0?users=${employee.Email}`} className={style.employeeTeamsLink}>
						<FontAwesomeIcon icon={faPhone} color="white" size="2x" fixedWidth={true} className={`${style.employeeTeamsLinkIcon}`} />
					</a>
				</Button>
				: null}
		</div>
		{employee.Id !== 0
			&& <>
				<section className={style.items}>
					<h3>Artikel</h3>
					<SplittedList<ItemRecord> list={employee.Items} getKey={e => e.Id}
						itemRender={item =>
							<ItemRecordComponent item={item} onClick={itemId => navigateToItem(navigation, itemId)} />
						}
					/>
				</section>
				<BottomRightCornerActions>
					<Share onShare={onShare} title="teile Mitarbeiter" />
				</BottomRightCornerActions>
			</>}
	</>
}

export default withAITracking(EmployeeDetails, "EmployeeDetails")
