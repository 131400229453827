import {
	FC
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
	faDesktop,
	faProjector,
	faMobile,
	faLaptop,
	faPrint,
	faTablet,
	faPhone,
	faServer,
	faHeadset,
	faCamera,
	faCube,
	faHdd,
	faLightbulb
} from "@fortawesome/pro-solid-svg-icons"
import style from "./style/ItemIcon.module.css"

interface Props {
	categoryId: number,
}

const categoryIcons: Map<number, IconDefinition> = new Map([
	[1, faHdd],
	[2, faDesktop],
	[3, faProjector],
	[4, faMobile],
	[5, faLaptop],
	[6, faPrint],
	[7, faTablet],
	[8, faPhone],
	[9, faServer],
	[10, faServer],
	[11, faHeadset],
	[12, faCamera],
	[13, faMobile],
	[14, faLightbulb]
])

const ItemIcon: FC<Props> = ({ categoryId }) => {
	const icon = categoryIcons.get(categoryId)

	return <span className={style.icon}>
		<FontAwesomeIcon icon={icon ? icon : faCube}
			rotation={categoryId === 1 ? 90 : undefined} />
	</span>
}

export default ItemIcon
