import {
	FC,
	Fragment
} from "react"
import style from "./style/SearchResultValue.module.css"

interface Props {
	value: string
	searchTerm: string,
}

const SearchResultValue: FC<Props> = ({
	value,
	searchTerm
}) => {
	const mappedMatches = [...(value.matchAll(new RegExp(searchTerm, "gi")))]
		.map(m => ({
			match: <span className={style.searchTerm}>
				{m[0]}
			</span>,
			// todo remove NumberConstructor if issue solved
			// https://github.com/microsoft/TypeScript/issues/36788
			index: Number(m.index)
		}))

	return mappedMatches.length > 0
		? <>
			{mappedMatches.map((m, i, a) =>
				<Fragment key={m.index}>
					{i === 0 && m.index > 0 ? value.substring(0, m.index) : null}
					{m.match}
					{value.substring(m.index + searchTerm.length, i === a.length - 1 ? undefined : a[i + 1]?.index)}
				</Fragment>)
			}
		</>
		: <>{value}</>
}

export default SearchResultValue
