import { FC } from "react"
import Employee from "../data/models/Employee"
import SplittedList from "./SplittedList"
import EmployeeRecord from "./EmployeeRecord"

interface Props {
	employees: readonly Employee[]
	onEmployeeClick: (employeeId: number) => void,
}

const Employees: FC<Props> = ({
	employees,
	onEmployeeClick
}) => {
	return <SplittedList<Employee> list={employees} getKey={k => k.Id}
		itemRender={i => <EmployeeRecord employee={i} onClick={onEmployeeClick} />} />
}

export default Employees
