import {
	FC
} from "react"
import { useNavigate } from "react-router-dom"
import withAITracking from "../applicationInsights/applicationInsights"
import EmployeeSelection from "./EmployeeSelection"
import BottomRightCornerActions from "./BottomRightCornerActions"
import { navigateToEmployee } from "../routes/routingFunctions"

const EmployeesFilteredSelection: FC = () => {
	const navigation = useNavigate()

	return <>
		<EmployeeSelection onEmployeeSelect={employeeId => navigateToEmployee(navigation, employeeId)} />
		<BottomRightCornerActions />
	</>
}

export default withAITracking(EmployeesFilteredSelection, EmployeesFilteredSelection.name)
