import {
	Fragment
} from "react"
import style from "./style/SplittedList.module.css"

interface Props<T> {
	list: readonly T[]
	getKey: (item: T) => number
	itemRender: (item: T) => JSX.Element,
}

// https://github.com/microsoft/TypeScript/issues/15713#issuecomment-499474386
const SplittedList = <T,>({ list, getKey, itemRender }: Props<T>) =>
	<div className={style.list}>
		{list.map((m, i) => <Fragment key={getKey(m)}>
			{i > 0 ? <div className={style.horizontalLine}></div> : null}
			{itemRender(m)}
		</Fragment>)}
	</div>

export default SplittedList
