import { FC } from "react"
import { useNavigate } from "react-router-dom"
import EmployeeItems from "../data/models/EmployeeItems"
import SplittedList from "./SplittedList"
import EmployeeItemsComponent from "./EmployeeItemsComponent"
import {
	navigateToEmployee,
	navigateToItem
} from "../routes/routingFunctions"

interface Props {
	employees: readonly EmployeeItems[],
}

const EmployeeItemsList: FC<Props> = ({ employees }) => {
	const navigation = useNavigate()

	return <SplittedList<EmployeeItems> list={employees}
		getKey={e => e.Id}
		itemRender={employee =>
			<EmployeeItemsComponent employee={employee}
				onEmployeeClick={employeeId => navigateToEmployee(navigation, employeeId)}
				onItemClick={itemId => navigateToItem(navigation, itemId)} />
		}
	/>
}

export default EmployeeItemsList
