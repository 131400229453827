import { groupBy } from "./utilityHelper"
import ReadOnlyItemDataModel from "./models/ReadOnlyItem"
import ReadOnlyItem from "../appmodels/ReadOnlyItem"
import Category from './models/Category'

export const storageStateId = 5

export const removedStateId = 3

export const itemWithDeviceNameCategoryIds: ReadonlySet<number> = new Set([1, 5, 6, 7])

export const toBeRemovedStateId = 7

export const rmaStateId = 2

export const takenOverByHRStateId = 8

export const rmaRoomId = 1094

export const noCategoryId = 0

export const beamerNavigationText = "Beamer"

export const createReadOnlyItem = (item: ReadOnlyItemDataModel): ReadOnlyItem => ({
	id: item.Id,
	name: item.Name,
	inventoryNumber: item.InventoryNumber,
	stateId: item.StateId,
	state: item.State,
	categoryId: item.CategoryId,
	category: item.Category,
	deviceName: item.DeviceName,
	serialNumber: item.SerialNumber,
	imei: item.IMEI,
	room: item.Room,
	employee: item.Employee,
	lampHours: item.LampHours,
	comment: item.Comment,
	itemHistory: item.ItemHistory
})

type CategorizedItem = {
	Name: string
	CategoryId: number
}

export const CreateCategoryGroupedItemsShareText = (
	categories: readonly Category[],
	items: readonly CategorizedItem[]) =>
	`${[...groupBy(i => i.CategoryId, items)]
		.map(m => ({
			key: m[0],
			values: m[1]
		}))
		.map(m =>
			`${categories.find(f => f.Id === m.key)?.Name ?? "unkategorisiert"}:
			${m.values.map(i => i.Name).join("\n")}`)
		.join("\n\n")}`
