import {
	FC,
	useCallback
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faHome,
	faBars,
	faChevronLeft
} from "@fortawesome/pro-solid-svg-icons"
import {
	Link,
	useNavigate
} from "react-router-dom"
import style from "./style/Header.module.scss"

interface Props {
	onMenuOpen: () => void,
}

const Header: FC<Props> = ({ onMenuOpen }) => {

	const navigation = useNavigate()

	const goBack = useCallback(() => navigation(-1), [navigation])

	return <header className={style.header}>
		<div className={style.headerItem}>
			<FontAwesomeIcon icon={faChevronLeft} size="2x" className={style.goBackIcon}
				onClick={goBack} title="Zurück" />
		</div>
		<div className={style.headerItem}>
			<Link to={`/`} title="Zur Startseite">
				<FontAwesomeIcon icon={faHome} size="2x" />
			</Link>
		</div>
		<div className={`${style.headerItem} ${style.menuOpener}`}>
			<FontAwesomeIcon icon={faBars} size="2x" className={style.menuOpenerIcon}
				onClick={onMenuOpen} title="Öffne Menü" />
		</div>
	</header>
}

export default Header
